<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div class="ForgotPassword">
      <div v-if="activities == 1||activities == 2">
        <div style="display: flex;align-items: center;justify-content: center;padding-top: 100px;">
          <div class="round" :class="{bgcActive: activities == 1||activities == 2||activities == 3}">
            <div class="title1" :class="{active: activities == 1||activities == 2||activities == 3}">通过手机号找回
            </div>
          </div>
          <div class="line" :class="{lineActive: activities == 2||activities == 3}"></div>
          <div class="round" :class="{bgcActive: activities == 2||activities == 3}">
            <div class="title2" :class="{active: activities == 2||activities == 3}">密码重置</div>
          </div>
          <div class="line" :class="{lineActive: activities == 3}"></div>
          <div class="round" :class="{bgcActive: activities == 3}">
            <div class="title3" :class="{active: activities == 3}">密码重置成功</div>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-top: 100px;">
          <el-form ref="ruleFormRef1" label-position="right" :model="ruleForm1" :rules="Rules1" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon v-if="activities==1">
            <el-form-item label="请输入账号：" prop="username">
              <el-input v-model="ruleForm1.username" placeholder="请输入账号" style="width: 260px;height:40px;" />
            </el-form-item>
            <el-form-item label="请输入手机号：" prop="phone">
              <el-input v-model="ruleForm1.phone" placeholder="请输入请输入手机号" style="width: 260px;height:40px;" @input="activeyzm(ruleFormRef1)" />
            </el-form-item>
            <el-form-item label="验证码：" prop="smsCode">
              <el-input v-model="ruleForm1.smsCode" placeholder="请输入验证码" style="width: 150px;height:40px;" />
              <el-button type="primary" :disabled='IsDisabled' style="width: 100px;height:40px;margin-left: 10px;" @click="madeyzm">{{title}}</el-button>
            </el-form-item>
            <el-form-item>
              <div style="width: 100%;display: flex;justify-content: center;align-items: center;padding-top: 50px;">
                <el-button type="primary" @click="submitForm(ruleFormRef1)" style="width: 150px;height: 40px;font-size: 14px;">
                  下一步
                </el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-form ref="ruleFormRef2" label-position="right" :model="ruleForm2" :rules="Rules2" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon v-if="activities==2">
            <el-form-item label="请输入新密码：" prop="newPassword">
              <el-input v-model="ruleForm2.newPassword" type="password" show-password placeholder="请输入新密码" style="width: 300px;height:40px;" />
            </el-form-item>
            <el-form-item label="请再次输入新密码：" prop="newPassword1">
              <el-input v-model="ruleForm2.newPassword1" type="password" show-password placeholder="请再次输入新密码" style="width: 300px;height:40px;" />
            </el-form-item>
            <el-form-item>
              <div style="width: 100%;display: flex;justify-content: center;align-items: center;padding-top: 50px;">
                <el-button type="primary" @click="submitFormL(ruleFormRef2)" style="width: 150px;height: 40px;font-size: 14px;">
                  下一步
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div v-if="activities==3">
        <div style="display:flex;justify-content: center;align-items: center;flex-direction: column;padding-top: 100px;">
          <div><img src="./../static/success.png" alt=""></div>
          <div style="font-size: 20px;color: rgba(32, 35, 50, 1);margin-top:24px;">密码重置成功</div>
          <div style="font-size:14px;color: rgba(79, 84, 112, 1);margin-top:20px;">您可以使用新密码，登录系统进行操作</div>
          <div class="btn" @click="login">账号登录</div>
        </div>
      </div>
    </div>
    <MyFooter style="margin-top: 0;" />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import md5 from "js-md5";
import {
  sendForgetPasswordSmsCode,
  forgetPasswordSmsCodeVerify,
  updatePassword
} from "@/api/index.js"
import { ref } from "vue";
import { useRouter } from "vue-router";
let router = useRouter()
let title = ref('获取验证码')
let IsDisabled = ref(true)
let activities = ref(1)
let formSize = ref('default')
let ruleFormRef1 = ref()
let ruleForm1 = ref({
  username: '',
  phone: '',
  smsCode: '',
})
let Rules1 = ref({
  username: [
    { required: true, message: '请输入账号', trigger: 'blur' },
  ],
  phone: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入手机号长度为11位', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
  ],
  smsCode: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ]
})
let ruleFormRef2 = ref()
let validatorPassword = (rule, value, callback) => {
  // 定义正则表达式来检查长度8-16并包含字母数字和特殊字符
  const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/
  if (pattern.test(value)) {
    callback()
  } else {
    callback(new Error('密码长度至少8位，必须包含字母、数字和特殊字符'))
  }
}
let ruleForm2 = ref({
  phone: '',
  newPassword: '',
  newPassword1: '',
})
let Rules2 = ref({
  newPassword: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 8, max: 16, message: '密码长度至少8位', trigger: 'blur' },
    { required: true, validator: validatorPassword, trigger: 'blur' }
  ],
  newPassword1: [
    { required: true, message: '请再次输入新密码', trigger: 'blur' },
    { min: 8, max: 16, message: '密码长度至少8位', trigger: 'blur' },
    { validator: (rule, value, callback) => { if (value !== ruleForm2.value.newPassword) { callback(new Error('两次输入密码不一致!')); } else { callback(); } }, trigger: 'blur' }
  ]
})
let submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      forgetPasswordSmsCodeVerify(ruleForm1.value).then(res => {
        if (res.code == 200) {
          activities.value = 2
        } else {
          return
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}
let submitFormL = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      ruleForm2.value.newPassword = md5(ruleForm2.value.newPassword)
      ruleForm2.value.newPassword1 = md5(ruleForm2.value.newPassword1)
      ruleForm2.value.phone = ruleForm1.value.phone
      updatePassword(ruleForm2.value).then(res => {
        if (res.code == 200) {
          activities.value = 3
        } else {
          return
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}
let activeyzm = (formName) => {
  formName.validateField('phone', (errorMessage) => {
    if (errorMessage) {
      IsDisabled.value = false;
    } else {
      console.log('Phone validation error:', errorMessage);
    }
  });
}
let madeyzm = async () => {
  await sendForgetPasswordSmsCode({ phone: ruleForm1.value.phone }).then((res) => {
    console.log(res);
  })
  let count = 60;
  title.value = `${count}秒后重新获取`;
  IsDisabled.value = true;
  let timer = setInterval(() => {
    count--;
    if (count > 0) {
      title.value = `${count}秒后重新获取`;
    } else {
      clearInterval(timer);
      title.value = '获取验证码';
      IsDisabled.value = false;
    }
  }, 1000);
}
let login = () => {
  router.push('/')
}
</script>
<style scoped>
::v-deep .el-form-item--small .el-form-item__label {
  line-height: 40px;
}
.btn {
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(31, 112, 247, 1) 0%,
    rgba(85, 155, 254, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 16px;
  box-sizing: border-box;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
}
.ForgotPassword {
  width: 100%;
  height: calc(100vh - 240px);
  background: url("./../static/bjt.png") no-repeat;
  background-size: 100% 100%;
}
.round {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(166, 166, 166, 1);
  position: relative;
}
.line {
  width: 200px;
  height: 0;
  border: 2px solid rgba(166, 166, 166, 1);
}
.title2 {
  position: absolute;
  left: -50%;
  bottom: -30px;
  transform: translateX(-18%);
  white-space: nowrap;
  color: rgba(166, 166, 166, 1);
}
.title1 {
  position: absolute;
  left: -50%;
  bottom: -30px;
  transform: translateX(-32%);
  white-space: nowrap;
  color: rgba(166, 166, 166, 1);
}
.title3 {
  position: absolute;
  left: -50%;
  bottom: -30px;
  transform: translateX(-27%);
  white-space: nowrap;
  color: rgba(166, 166, 166, 1);
}
.bgcActive {
  background-color: rgb(31, 112, 247);
}
.lineActive {
  border: 2px solid rgb(31, 112, 247);
}
.active {
  color: rgb(31, 112, 247);
}
</style>
