<template>
  <div>
    <el-form ref="ruleFormRef" style="width: 100%" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
      <el-form-item label="" prop="username">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.username" size="default" placeholder="请输入账号" :prefix-icon="User" style="width: 300px;" />
      </el-form-item>
      <el-form-item label="" prop="passwordClone">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.passwordClone" type="password" size="default" placeholder="请输入密码" :prefix-icon="Lock" style="width: 300px;" />
      </el-form-item>
      <el-form-item label="" prop="phone">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.phone" size="default" placeholder="请输入手机号" :prefix-icon="Iphone" style="width: 300px;" @input="activeyzm(ruleFormRef)" />
      </el-form-item>
      <el-form-item label="" prop="captchaCode">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.captchaCode" size="default" placeholder="请输入验证码" :prefix-icon="CopyDocument" style="width: 200px;" />
        <div style="width: 100px;height:50px;">
          <el-button :disabled='IsDisabled' type="primary" size="default" style="width: 100%;height:100%;" @click="getyzm">{{title}}</el-button>
        </div>
      </el-form-item>
      <!--<el-form-item label="" prop="captchaCode">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.captchaCode" size="default" placeholder="请输入验证码" :prefix-icon="CopyDocument" style="width: 200px;" />
        <div style="width: 100px;height:50px;cursor: pointer;" @click="getImg"><img :src="img" alt="" style="width: 100%;height: 100%;object-fit: fill;"></div>
      </el-form-item>-->
      <el-form-item label="" prop="">
        <div style="width: 100%;display: flex;justify-content: space-between;align-items: center">
          <div style="font-size: 14px;color:rgb(96,98,102);display:flex;align-items:center;">
            <div>没有账户？立即</div>
            <div style="color: rgba(255, 141, 26, 1);margin-left:2px;cursor: pointer;" @click="enroll">注册</div>
          </div>
          <div style="font-size: 14px;color:rgb(96,98,102);display:flex;align-items:center;" @click="forget">
            <div>忘记密码</div>
            <div style="width: 16px;height:16px;border-radius:50%;display:flex;justify-content: center;align-items: center;margin-left:5px;cursor: pointer;border:1px solid rgb(96,98,102);">?</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="submitForm(ruleFormRef)" style="width: 100%;height: 55px;font-size:18px;margin-top: 50px;">
          立即登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import md5 from "js-md5";
import inspect from "@/utils/validate";
import { User, Lock, CopyDocument,Iphone } from '@element-plus/icons-vue'
import { reactive, ref, onMounted } from 'vue'
import { useRouter } from "vue-router";
import { captcha, token,Phonevalidate } from "@/api/index.js"
// import { Phonetoken, Phonevalidate } from "@/api/index.js"
import { ElMessage, ElMessageBox } from 'element-plus'

let router = useRouter()
let fullscreenLoading = ref(false)
let formSize = ref('default')
let ruleFormRef = ref()
let img = ref('')
let imgKey = ref('')
let title = ref('获取验证码')
let CaptchaKey = ref()
let ruleForm = reactive({
  username: '',
  password: '',
  passwordClone: '',
  phone: '',
  captchaCode: '',
  grant_type: "captcha",
})
let IsDisabled = ref(true)
let validateTelphone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('手机号不能为空！'));
  } else if (!inspect.valphone(value)) {
    callback(new Error('请输入正确的手机号！'));
  } else {
    callback();
  }
};
const validatorPassword = (rule, value, callback) => {
  // 定义正则表达式来检查长度8-16并包含字母数字和特殊字符
  const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/
  if (pattern.test(value)) {
    callback()
  } else {
    callback(new Error('密码长度至少8位，必须包含字母、数字和特殊字符'))
  }
}
let rules = reactive({
  username: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { min: 3, max: 20, message: '账号长度在3-20个字符之间', trigger: 'blur' },
  ],
  passwordClone: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    // { min: 8, max: 16, message: '密码长度至少8位', trigger: 'blur' },
    // { validator: validatorPassword, trigger: 'blur' },
  ],
  captchaCode: [
    { required: true, message: '请输入验证码', trigger: 'blur' }
  ],
  phone: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' },
    { required: true, validator: validateTelphone, trigger: 'blur' }
  ],
  // yzm: [
  //   { required: true, message: '请输入验证码', trigger: 'blur' }
  // ],
})
let submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      fullscreenLoading.value = true
      ruleForm.password = ruleForm.passwordClone
      ruleForm.password = md5(ruleForm.password)
      const { passwordClone, ...newObject } = ruleForm
      token(ruleForm.captchaCode,CaptchaKey.value, newObject).then((res) => {
        if (res.code == 200) {
          localStorage.setItem('tokenuser', res.access_token);
          localStorage.setItem('token', JSON.stringify(res));
          localStorage.setItem('Time', new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
          // 登录密码已过期
          if(res.pwdExpire == 0) {
            ElMessageBox.confirm('您的登录密码已过期, 是否立即修改密码?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              router.push({ path: '/forgotPassword' })
            }).catch(() => {
              router.push('/')
              location.reload();
              // ElMessage({
              //   type: 'info',
              //   message: '已取消'
              // });          
            });
          } else {
            router.push('/')
            location.reload();
          }
        } else {
          fullscreenLoading.value = false
          IsDisabled.value = true;
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
  fullscreenLoading.value = false
}
let activeyzm = (formName) => {
  formName.validateField('phone', (errorMessage) => {
    if (errorMessage) {
      IsDisabled.value = false;
    } else {
      console.log('Phone validation error:', errorMessage);
    }
  });
}
let getyzm = async () => {
  console.log(ruleForm.phone, '手机号');
  await Phonevalidate({ phone: ruleForm.phone }).then((res) => {
    console.log(res, 'res');
    CaptchaKey.value = res.data.id
  })
  let count = 60;
  title.value = `${count}秒后重新获取`;
  IsDisabled.value = true;
  let timer = setInterval(() => {
    count--;
    if (count > 0) {
      title.value = `${count}秒后重新获取`;
    } else {
      clearInterval(timer);
      title.value = '获取验证码';
      IsDisabled.value = false;
    }
  }, 1000);
}
let enroll = () => {
  router.push({ path: '/businessRegistration' })
}
let forget = () => {
  router.push({ path: '/forgotPassword' })
}
let getImg = () => {
  imgArr()
}
let imgArr = async () => {
  await captcha().then((res) => {
    imgKey.value = res.key
    img.value = res.image
  })
}
let handleWatchEnter = (e) => {
  if (e.keyCode === 13) {
    console.log(123);
    submitForm(ruleFormRef.value)
  }
}
onMounted(async () => {
  // await imgArr()
})
</script>
<style scoped>
::v-deep .el-input__wrapper {
  height: 50px;
  background: rgba(231, 241, 253, 0.4);
  box-shadow: none;
}
::v-deep .el-input__prefix-inner {
  position: relative;
}
::v-deep .el-input__prefix-inner::after {
  content: "";
  width: 1px;
  height: 28px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background-color: rgb(4, 19, 74);
  opacity: 0.4;
}
::v-deep .el-input__inner {
  margin-left: 10px;
}
</style>