// http://192.168.0.62:8077/api/fontController/fontController/
// import request from "@/core/request"
import request from "@/util/axios"
import { getHeaders } from "@/util";
const checkIsAuthorized = () => {
  return request.request({
    url: '/api/sys/company/isAuthorized',
    headers: getHeaders(),
    method: 'GET'
  })
}
const list = (params) => {
  return request.request({
    url: '/api/cms/openNews/list',
    method: 'GET',
    params
  })
}
const detailByRegion = (params) => {
  return request.request({
    url: '/api/cms/openNews/detailByRegion',
    method: 'GET',
    params
  })
}
const token = (captchaCode, captchaKey, data) => {
  return request.request({
    url: '/api/cms/login/token',
    method: 'post',
    headers: {
      "Captcha-Code": captchaCode,
      "Authorization": "Basic c2FiZXIzOnNhYmVyM19zZWNyZXQ=",
      "Captcha-Key": captchaKey
    },
    params: data
  })
}
const Phonetoken = (captchaCode, captchaKey, data) => {
  return request.request({
    url: '/api/cms/login/token',
    method: 'post',
    headers: {
      "Captcha-Key": captchaKey,
      "Captcha-Code": captchaCode,
      "Authorization": "Basic c2FiZXIzOnNhYmVyM19zZWNyZXQ=",
    },
    params: {
      ...data
    }
  })
}
const Phonevalidate = (data) => {
  return request.request({
    url: '/api/cms/login/send-phone-login-validate',
    method: 'post',
    params: {
      ...data
    }
  })
}

const registerPhonevalidate = (data) => {
  return request.request({
    url: '/api/cms/login/send-phone-register-validate',
    method: 'post',
    params: {
      ...data
    }
  })
}

const captcha = () => {
  return request.request({
    url: '/api/blade-auth/oauth/captcha',
    method: 'GET',
  })
}

const Alloeders = (data) => {
  return request.request({
    url: '/api/cms/order/all',
    method: 'GET',
    params: {
      ...data
    }
  })
}
const lazyTree = (data) => {
  return request.request({
    url: '/api/blade-system/region/lazy-tree',
    method: 'GET',
    params: {
      ...data
    }
  })
}
const detail = (data) => {
  return request.request({
    url: '/api/cms/openNews/detail',
    method: 'GET',
    params: {
      ...data
    }
  })
}
const payState = (data) => {
  return request.request({
    url: '/api/blade-system/dict-biz/dictionary?code=' + data,
    method: 'GET'
  })
}
const applyState = (data) => {
  return request.request({
    url: '/api/blade-system/dict-biz/dictionary?code=' + data,
    method: 'GET'
  })
}
const applyProjectAttachList = () => {
  return request.request({
    url: '/api/blade-system/param/detail?paramKey=apply_project_attach',
    method: 'get',
  })
}
const RegulatoryEnd = () => {
  return request.request({
    url: '/api/blade-system/param/detail?paramKey=person_url',
    method: 'get',
  })
}
const CoverageSide = () => {
  return request.request({
    url: '/api/blade-system/param/detail?paramKey=underwriting_url',
    method: 'get',
  })
}
const submit = (captchaKey, captchaCode, data) => {
  return request.request({
    url: '/api/cms/apply/project/submit',
    headers: {
      "Captcha-Key": captchaKey,
      "Captcha-Code": captchaCode,
      "Authorization": "Basic c2FiZXIzOnNhYmVyM19zZWNyZXQ=",
    },
    method: 'post',
    data
  })
}
const search = () => {
  return request.request({
    url: '/api/cms/company/search',
    method: 'get',
  })
}
const searchUnderwritingList = (params) => {
  return request.request({
    url: '/api/cms/company/searchUnderwritingList',
    method: 'get',
    params
  })
}

const searchCompany = (params) => {
  return request.request({
    url: '/api/cms/company/search',
    method: 'get',
    params
  })
}
const getLastRecord = (deptId) => {
  return request.request({
    url: '/api/cms/company/getLastRecord',
    method: 'get',
    params: {
      deptId
    }
  })
}

const underReview = () => {
  return request.request({
    url: '/api/blade-companyInfoRecord/companyInfoRecord/underReview',
    method: 'get',
  })
}
const searchProject = (params) => {
  return request.request({
    url: '/api/cms/apply/searchProject',
    method: 'get',
    params
  })
}
const searchApply = (params) => {
  return request.request({
    url: '/api/cms/apply/searchApply',
    method: 'get',
    params
  })
}
const getPayInfo = (params) => {
  return request.request({
    url: '/api/cms/order/getPayInfo',
    method: 'get',
    params
  })
}
const genQRCode = (params) => {
  return request.request({
    url: '/api/cms/order/genQRCode',
    method: 'get',
    params
  })
}
const applyInfoSubmit = (data) => {
  return request.request({
    url: '/api/cms/apply/applyInfo/submit',
    headers: getHeaders(),
    method: 'post',
    data
  })
}
const submitCompanyInfo = (captchaCode, captchaKey, row) => {
  return request.request({
    url: '/api/blade-companyInfoRecord/companyInfoRecord/submitCompanyInfo',
    method: 'post',
    headers: {
      "Captcha-Key": captchaKey,
      "Captcha-Code": captchaCode,
      "Authorization": "Basic c2FiZXIzOnNhYmVyM19zZWNyZXQ=",
      "Content-Type": "multipart/form-data"
    },
    data: row
  })
}
const searchSlefOrder = (params) => {
  return request.request({
    url: '/api/cms/order/searchSlefOrder',
    method: 'get',
    params
  })
}
const refreshToken = (refresh_token) => {
  return request.request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': '000000',
      'Dept-Id': '',
      'Role-Id': '',
      "Authorization": "Basic c2FiZXIzOnNhYmVyM19zZWNyZXQ=",
    },
    params: {
      tenantId: '000000',
      refresh_token,
      grant_type: 'refresh_token',
      scope: 'all',
    }
  })
}
const uploadContract = (data) => {
  return request.request({
    url: '/api/biz-contract/contract/uploadContract',
    method: 'post',
    data
  })
}

const companyInfo = () => {
  return request.request({
    url: '/api/cms/company/company-info-manage',
    method: 'get',
  })
}
const companyInfoUpdate = (data) => {
  return request.request({
    url: '/api/cms/company/company-info-update',
    method: 'post',
    data
  })
}

const CmsList = (params) => {
  return request.request({
    url: '/api/biz-contract/contract/cms-list',
    method: 'get',
    params
  })
}
const getCmsList = (params) => {
  return request.request({
    url: '/api/blade-team/team/cms-page',
    method: 'get',
    params
  })
}
const WorkgetCmsList = (current, size, params) => {
  return request.request({
    url: '/api/blade-worker/worker/cms-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
const Mesagelist = (current, size) => {
  return request.request({
    url: '/api/cms/messageNotice/list',
    method: 'get',
    params: {
      current,
      size,
    }
  })
}

// const uploadFile = (params) => {
//   return request.request({
//     url: '/api/cms/regulatoryEnd',
//     method: 'get',
//     params
//   })
// }
const InvoiceSubmit = (data) => {
  return request.request({
    url: '/api/cms/invoice/submit',
    method: 'post',
    data
  })
}
const InvoiceDetail = (data) => {
  return request.request({
    url: '/api/cms/invoice/detail',
    method: 'get',
    params: {
      ...data
    }
  })
}
const getByProject = (data) => {
  return request.request({
    url: '/api/cms/OpenSurrender/getByProject',
    method: 'get',
    params: {
      ...data
    }
  })
}
const saveSurrender = (data) => {
  return request.request({
    url: '/api/cms/OpenSurrender/saveSurrender',
    method: 'post',
    data
  })
}
const saveNewSurrender = (data) => {
  return request.request({
    url: '/api/cms/OpenSurrender/saveNewSurrender',
    method: 'post',
    data
  })
}
const cancelOrder = (data) => {
  return request.request({
    url: '/api/cms/order/cancelOrder',
    method: 'post',
    data
  })
}
const sendForgetPasswordSmsCode = (data) => {
  return request.request({
    url: '/api/cms/login/sendForgetPasswordSmsCode',
    method: 'post',
    params: {
      ...data
    }
  })
}
const forgetPasswordSmsCodeVerify = (data) => {
  return request.request({
    url: '/api/cms/login/forgetPasswordSmsCodeVerify',
    method: 'post',
    params: {
      ...data
    }
  })
}
const updatePassword = (data) => {
  return request.request({
    url: '/api/cms/login/update-password',
    method: 'post',
    params: {
      ...data
    }
  })
}
const selfGuaranteeOrder = (data) => {
  return request.request({
    url: '/api/cms/order/selfGuaranteeOrder',
    method: 'get',
    params: {
      ...data
    }
  })
}
const selfReadyMoneyOrder = (data) => {
  return request.request({
    url: '/api/cms/order/selfReadyMoneyOrder',
    method: 'get',
    params: {
      ...data
    }
  })
}
const checkoutAccountApi = (account) => {
  return request.request({
    url: '/api/blade-companyInfoRecord/companyInfoRecord/checkoutAccount',
    method: 'post',
    params: {
      account,
    },
  });
};

const checkoutPhoneApi = (phone) => {
  return request.request({
    url: '/api/blade-companyInfoRecord/companyInfoRecord/checkoutPhone',
    method: 'post',
    params: {
      phone,
    },
  });
};
const getList = (current, size, params) => {
  return request.request({
    url: '/api/guaranteeCheck/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
const getUnCheckProject = () => {
  return request.request({
    url: '/api/guaranteeCheck/un-check-project-bid',
    method: 'get',
  })
}
const getDetailByPersona = (params) => {
  return request.request({
    url: '/api/cms/company/detailByPersona',
    method: 'get',
    params: {
      ...params
    }
  })
}
const add = (row) => {
  return request.request({
    url: '/api/guaranteeCheck/submit',
    method: 'post',
    data: row
  })
}
const selfCompensation = (params) => {
  return request.request({
    url: '/api/cms/order/selfCompensation',
    method: 'get',
    params: {
      ...params,
    }
  })
}
const searchUnderwriting = (params) => {
  return request.request({
    url: '/api/cms/company/searchUnderwriting',
    method: 'get',
    params: {
      ...params,
    }
  })
}
const detailRateConfig = (params) => {
  return request.request({
    url: '/api/cms/openNews/detailRateConfig',
    method: 'get',
    params: {
      ...params,
    }
  })
}
// 获取行业类型
const reqIndustryCategory = (data) => {
  return request.request({
    url: '/api/blade-system/dict/dictionary?code=' + data,
    method: 'GET'
  })
}
export {
  detailRateConfig,
  detailByRegion,
  searchUnderwriting,
  selfReadyMoneyOrder,
  selfCompensation,
  searchApply,
  getList,
  getUnCheckProject,
  getDetailByPersona,
  add,
  checkoutAccountApi,
  checkoutPhoneApi,
  checkIsAuthorized,
  list,
  token,
  Phonetoken,
  Phonevalidate,
  captcha,
  Alloeders,
  lazyTree,
  detail,
  payState,
  applyState,
  submit,
  search,
  searchUnderwritingList,
  searchProject,
  getPayInfo,
  genQRCode,
  applyInfoSubmit,
  submitCompanyInfo,
  searchSlefOrder,
  applyProjectAttachList,
  refreshToken,
  uploadContract,
  CmsList,
  getCmsList,
  WorkgetCmsList,
  RegulatoryEnd,
  CoverageSide,
  Mesagelist,
  searchCompany,
  underReview,
  companyInfo,
  companyInfoUpdate,
  InvoiceSubmit,
  InvoiceDetail,
  getByProject,
  saveSurrender,
  saveNewSurrender,
  cancelOrder,
  sendForgetPasswordSmsCode,
  forgetPasswordSmsCodeVerify,
  updatePassword,
  selfGuaranteeOrder,
  getLastRecord,
  registerPhonevalidate,
  reqIndustryCategory
}
