<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="title-img">
          <div style="font-size: 42px;font-weight: 500;color: rgba(0, 82, 217, 1);position: relative;">
            <div>智能农民工用工管理平台</div>
            <div style="width: 420px;position: absolute;bottom: -40px;left: 20px;"><img src="./../static/zntb.png" alt=""></div>
          </div>
          <div style="font-size: 24px;font-weight: 400;color: rgba(102, 102, 102, 1);margin-top:10px;">项目申报 急速办理 合规监管</div>
        </div>
        <div class="title-tip w1300">
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item,index) in titleArr" :key="item.id" style="width: calc(100% / 7);display: flex;flex-direction: column;align-items: center;">
              <div style="width: 75px;height: 52px;padding-left: 12px;"><img :src="item.url" alt="" style="object-fit:none;margin-left: 5px;" :style="index==2?'margin-left: 15px;':''"></div>
              <div style="display: flex;align-items: center;padding-top:15px;">
                <div style="width: 83px;height: 0px;border: 1px dashed rgb(42, 123, 234);" :style="index>0?'border: 1px dashed rgb(166,166,166);':''"></div>
                <div style="width: 16px;height: 16px;background: rgb(42, 123, 234);border-radius:50%;" :style="index>0?'background: rgb(215, 215, 215);':''"></div>
                <div style="width: 83px;height: 0px;border: 1px dashed rgb(166,166,166);"></div>
              </div>
              <div style="padding-top:15px;font-size: 18px;color: rgb(69, 87, 124);" :style="index>0?'color: rgb(215, 215, 215);':''">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="SignUpForms">
        <div class="w1300 SignUpForms-box">
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">项目基本信息</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="所属区域:" prop="regionClone">
                    <el-cascader filterable v-model="regionClone" placeholder='请选择区域' :options="treeArr" :props="optionProps" size='default' style="width: 260px;height: 40px;" @change="handleChange" />
                  </el-form-item>
                  <el-form-item label="项目全称:" prop="projectName">
                    <el-input v-model="ruleForm.projectName" placeholder="请输入项目全称" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="建设单位:" prop="companyName">
                    <el-input v-model="ruleForm.companyName" placeholder="请输入建设单位" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目联系人:" prop="contacts">
                    <el-input v-model="ruleForm.contacts" placeholder="请输入项目联系人" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="联系方式:" prop="contactInfo">
                    <el-input v-model="ruleForm.contactInfo" placeholder="请输入联系方式" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="行业类别：" prop="industryCategory">
                    <el-select v-model="ruleForm.industryCategory" placeholder="请选择行业类别" style="width: 260px;height:40px;">
                        <el-option v-for="(item,index) in industryCategoryArr" :key="item.id+index" :label="item.dictValue" :value="item.dictKey" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="项目地点:" prop="place">
                    <el-input v-model="ruleForm.place" placeholder="请输入项目地点" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间:" prop="projectStartTime">
                    <el-date-picker format="YYYY-MM-DD" clear value-format="YYYY-MM-DD HH:mm:ss" v-model="ruleForm.projectStartTime" type="date" placeholder="请选择开工时间" size='large' style="width: 260px;" @change="StartTime" />
                  </el-form-item>
                  <el-form-item label="完工时间:" prop="projectEndTime">
                    <el-date-picker format="YYYY-MM-DD" clear value-format="YYYY-MM-DD HH:mm:ss" v-model="ruleForm.projectEndTime" type="date" placeholder="请选择完工时间" size='large' style="width: 260px;" @change="EndTime" />
                  </el-form-item>
                  <el-form-item label="合同签订日期:" prop="signingDate">
                    <el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" v-model="ruleForm.signingDate" type="date" placeholder="请选择合同签订日期" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(天):" prop="contractDuration">
                    <el-input v-model="ruleForm.contractDuration" @input="Duration(ruleForm.contractDuration)" placeholder="请输入合同工期" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="施工许可证(或开工报告)批复日期:" prop="approvalDate">
                    <el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" v-model="ruleForm.approvalDate" type="date" placeholder="请选择施工许可证批复日期" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元):" prop="contractAmount">
                    <el-input v-model="ruleForm.contractAmount" placeholder="请输入合同金额" style="width: 260px;height:40px;" @input="contractAmountInput(ruleForm.contractAmount)" />
                  </el-form-item>
                  <el-form-item label="核定担保比例:" prop="guaranteeRatio">
                    <el-input :disabled="pubShow" v-model="ruleForm.guaranteeRatio" placeholder="请输入核定担保比例" style="width: 260px;height:40px;" @input="guaranteeRatioInput(ruleForm.guaranteeRatio)">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="核定担保金额(元):" prop="guaranteeAmount">
                    <el-input disabled v-model="ruleForm.guaranteeAmount" placeholder="请输入核定担保金额" style="width: 260px;height:40px;" @input="guaranteeAmountInput(ruleForm.guaranteeAmount)" />
                  </el-form-item>
                  <el-form-item label="项目编号:" prop="projectCode">
                    <el-input v-model="ruleForm.projectCode" placeholder="请输入项目编号" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="资金来源:" prop="fundingSource">
                    <el-input v-model="ruleForm.fundingSource" placeholder="请输入资金来源" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="缴存形式:" prop="applyWay">
                    <el-select v-model="ruleForm.applyWay" @change="applyWayChange" placeholder="请选择缴存形式" :disabled='show' style="width: 260px;height:40px;">
                      <el-option label="现金缴存" value="readyMoney" />
                      <el-option label="银行保函" value="bank" />
                      <el-option label="保证保险" value="bond" />
                      <el-option label="担保保函" value="guarantee" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="承保机构:" prop="institutionName">
                    <el-input v-if="selsectShow" :disabled='boxShow' v-model="ruleForm.institutionName" placeholder="请输入承保机构" style="width: 260px;height:40px;" />
                    <el-select v-else :disabled='boxShow' v-model="ruleForm.institutionName" @change="institutionNameChange" placeholder="请选择承保机构" style="width: 260px;height:40px;" clearable>
                      <el-option :label="item.deptName" :value="item.deptName" v-for="item in changeList" :key="item.id" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="联系方式:" prop="institutionContact">
                    <el-input :disabled='mrShow' v-model="ruleForm.institutionContact" placeholder="请输入联系方式" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <div v-if="AlgorithmicBasisList!=null&&AlgorithmicBasisList!=''&&AlgorithmicBasisList.completeEvidence!=null&&AlgorithmicBasisList.completeEvidence!=''" style="width: 100%;border-radius: 6px;background: rgb(255, 255, 255);box-shadow: 0px 1px 0px  rgba(17, 17, 26, 0.05), 0px 0px 8px 2px rgba(0, 82, 217, 0.1);padding:20px 35px;box-sizing: border-box">
                    <div class="img-tip">
                      <div class="number-tip" style="margin-left:0;height: 30px;line-height: 30px;">核算依据信息</div>
                    </div>
                    <div v-html="AlgorithmicBasisList.completeEvidence"></div>
                    <!-- <div style="display: flex;margin-top: 15px;">
                      <div><img src="./../static/waring.png" alt="" style="width: 14px;height: 14px;"></div>
                      <div style="margin-left: 10px;font-size: 16px;font-weight: 700;color: rgb(0, 0, 0);">标准存储比例</div>
                    </div>
                    <div style="margin-top: 10px;margin-left: 20px;">
                      <div style="font-size: 14px;color: rgb(0, 0, 0);">· 工程施工合同额(或年度合同额)的2%存储，单个工程项目存储额最高不超800万元。</div>
                      <div style="font-size: 14px;color: rgb(0, 0, 0);margin-top: 10px;">· 总包单位在本市有2个以上在建工程项目，其存储比例下浮为1.5%。</div>
                    </div>
                    <div style="display: flex;margin-top: 30px;">
                      <div><img src="./../static/waring.png" alt="" style="width: 14px;height: 14px;"></div>
                      <div style="margin-left: 10px;font-size: 16px;font-weight: 700;color: rgb(0, 0, 0);">申请减免</div>
                    </div>
                    <div style="margin-top: 10px;margin-left: 20px;">
                      <div style="font-size: 14px;color: rgb(0, 0, 0);">· 施工合同低于300万元的工程项目，且该工程项目的总包单位在签订施工合同前一年内承建的工程项目未发生工资拖欠的,免除该工程项目存储工资保证金。</div>
                      <div style="font-size: 14px;color: rgb(0, 0, 0);margin-top: 10px;">· 总包单位存储工资保证金或提交银行保函、工程担保公司保函、工程保证保险后，在本市承建工程连续2年未发生工资拖欠的，其新承建工程项目工资保证金存储比例为1%。</div>
                      <div style="font-size: 14px;color: rgb(0, 0, 0);margin-top: 10px;">· 总包单位存储工资保证金或提交银行保函、工程担保公司保函、工程保证保险后，在本市承建工程连续2年未发生工资拖欠的，其新承建工程项目工资保证金存储比例为1%。</div>
                      <div style="font-size: 14px;color: rgb(0, 0, 0);margin-top: 10px;">· 总包单位存储工资保证金或提交银行保函、工程担保公司保函、工程保证保险后，在本市承建工程连续2年未发生工资拖欠的，有2个以上新承建工程项目，其存储比例下浮为0.5%。</div>
                      <div style="font-size: 14px;color: rgb(0, 0, 0);margin-top: 10px;">· 总包单位存储工资保证金或提交银行保函、工程担保公司保函、工程保证保险后，连续3年未发生工资拖欠且按照要求落实用工实名制管理和农民工工资专用账户制度的，其新承建工程项目可免于存储工资保证金。</div>
                    </div>
                    <div style="display: flex;margin-top: 30px;">
                      <div><img src="./../static/waring.png" alt="" style="width: 14px;height: 14px;"></div>
                      <div style="margin-left: 10px;font-size: 16px;font-weight: 700;color: rgb(0, 0, 0);">申请提高</div>
                    </div>
                    <div style="margin-top: 10px;margin-left: 20px;">
                      <div style="font-size: 14px;color: rgb(0, 0, 0);">· 总包单位存储工资保证全或提交银行保函、工程担保公司保函、工程保证保险前2年内，在本市承建工程项目发生工资拖欠的，工资保证金存储比例为3%且不得下浮，不受单个工程项目设定存储上限限制。</div>
                      <div style="font-size: 14px;color: rgb(0, 0, 0);margin-top: 10px;">· 因拖欠农民工工资被纳入“失信联合惩戒名单”的,工资保证金存储比例为5%且不得下浮,不受单个工程项目设定存储上限限制。</div>
                    </div> -->
                  </div>
                  <div style="margin-top:50px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                      <div class="number-tip">申请企业信息（施工单位）</div>
                    </div>
                    <div style="margin-top:35px;">
                      <el-form-item label="企业名称:" prop="applyCompanyName">
                        <el-input :disabled='true' v-model="ruleForm.applyCompanyName" placeholder="请输入企业名称" style="width: 260px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="信用代码:" prop="creditCode">
                        <el-input :disabled='true' v-model="ruleForm.creditCode" placeholder="请输入信用代码" style="width: 260px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="法人姓名:" prop="legalName">
                        <el-input :disabled='true' v-model="ruleForm.legalName" placeholder="请输入法定姓名" style="width: 260px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="法人联系电话:" prop="legalPhone">
                        <el-input :disabled='true' autocomplete=“new-password” v-model="ruleForm.legalPhone" placeholder="请输入法定联系电话" style="width: 260px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="经办人:" prop="operatorName">
                        <el-input v-model="ruleForm.operatorName" placeholder="请输入经办人" style="width: 260px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="经办人电话:" prop="operatorPhone">
                        <el-input autocomplete=“new-password” v-model="ruleForm.operatorPhone" placeholder="请输入经办人电话" @input="activeyzm(ruleFormRef)" style="width: 260px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="验证码:" prop="yzm">
                        <el-input v-model="ruleForm.yzm" style="width: 150px;height:40px;" placeholder="请输入验证码" />
                        <el-button :disabled='IsDisabled' type="primary" style="width: 100px;height:40px;margin-left: 10px;" @click="madeyzm">{{title}}
                        </el-button>
                      </el-form-item>
                    </div>
                    <div style="margin-top:50px;">
                      <div class="img-tip">
                        <div class="number-img"><img src="./../static/03-img.png" alt=""></div>
                        <div class="number-tip">附件</div>
                      </div>
                      <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap: wrap;">
                        <div class="annex">
                          <el-form-item label="开户许可证：" prop="license_certificate" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.license_certificate" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove1" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                        <div class="annex">
                          <el-form-item label="工商营业执照：" prop="apply_business_license" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.apply_business_license" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove2" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile1">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                        <div class="annex">
                          <el-form-item label="施工总承包合同：" prop="general_onstruction_contract" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.general_onstruction_contract" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove3" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile2">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                        <div class="annex">
                          <el-form-item label="项目中标通知书：" prop="bid_win_notice" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.bid_win_notice" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove4" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile3">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                        <div class="annex">
                          <el-form-item label="施工许可证或开工许可证明：" prop="licence" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.licence" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove5" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile4">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                        <div class="annex">
                          <el-form-item label="建筑企业资质证明：" prop="qualifications" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.qualifications" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove6" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile5">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                        <!-- <div class="annex">
                          <el-form-item prop="commitment_letter" style="display: flex;flex-direction:column;">
                            <template #label>
                              <span>承诺书<span style="color:rgb(212, 48, 48)">(请在下载承诺书文件签字盖章后上传)</span>:</span>
                            </template>
                            <el-upload :file-list="ruleForm.commitment_letter" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile6">
                              <template #trigger>
                                <el-button type="primary" :icon="Upload">上传附件</el-button>
                              </template>
                              <template #default>
                                <el-button type="primary" style="margin-top:-7px;margin-left:10px;" :icon="Download" @click="DownloadList">
                                  下载承诺书
                                </el-button>
                              </template>
                            </el-upload>
                          </el-form-item>
                        </div> -->
                        <div class="annex">
                          <el-form-item label="其他附件(劳资专管员资聘书、工作证、合同等)：" style="display: flex;flex-direction:column;">
                            <el-upload :file-list="ruleForm.project_other" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove7" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile7">
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </el-upload>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;padding-top: 50px;">
              <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitForm(ruleFormRef)" style="width: 150px;height: 40px;font-size: 14px;">提交申请</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import { Upload } from '@element-plus/icons-vue'
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { lazyTree, Phonevalidate, applyState, submit, search, applyProjectAttachList, searchSlefOrder, detailByRegion, searchUnderwritingList,reqIndustryCategory } from "@/api/index.js"
import { useRouter } from "vue-router";
import inspect from "@/utils/validate";
import { ref, onMounted,reactive } from "vue";
let fullscreenLoading = ref(false)
let show = ref(true)
let mrShow = ref(true)
let selsectShow = ref(true)
let boxShow = ref(true)
let CaptchaKey = ref()
let Disabled = ref(true)
let applyProjectAttach = ref()
let ProjectAttachList = ref()
let title = ref('获取验证码')
let IsDisabled = ref(true)
let optionProps = ref({
  value: "id",
  label: "title",
  children: "children",
  checkStrictly: true,
})
let treeArr = ref()
let titleArr = ref([
  // { id: 1, url: require('./../static/step1.png'), title: '项目信息申报' },
  // { id: 3, url: require('./../static/ico-03.png'), title: '保证金核定' },
  // { id: 5, url: require('./../static/ico-04.png'), title: '三制落实' },
  // { id: 6, url: require('./../static/ico-05.png'), title: '季度申报' },
  // { id: 7, url: require('./../static/ico-02.png'), title: '工资监管' },
  { id: 1, url: require('./../static/step1.png'), title: '项目申请' },
  { id: 3, url: require('./../static/ico-02.png'), title: '项目审核' },
  { id: 5, url: require('./../static/ico-03.png'), title: '保证申请' },
  { id: 6, url: require('./../static/ico-02.png'), title: '机构审核' },
  { id: 7, url: require('./../static/ico-04.png'), title: '费用缴纳' },
  { id: 8, url: require('./../static/ico-05.png'), title: '出纳' },
  { id: 9, url: require('./../static/ico-02.png'), title: '人社审核' },
])
let pubShow = ref(true)
let maxRate = ref()
let minRate = ref()
let router = useRouter()
let formSize = ref('default')
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let ruleFormRef = ref()
let AlgorithmicBasisList = ref()
let validateTelphone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('手机号不能为空！'));
  } else if (!inspect.valphone(value)) {
    callback(new Error('请输入正确的手机号！'));
  } else {
    callback();
  }
};
let validateTelTime = (rule, value, callback) => {
  if (value < ruleForm.value.projectStartTime) {
    callback(new Error('完工时间不能小于开工时间'));
  } else {
    callback();
  }
};
let validateTelguaranteeRatio = (rule, value, callback) => {
  if (value < Number(minRate.value) || value > Number(maxRate.value)) {
    callback(new Error(`核定担保比例在${minRate.value}%~${maxRate.value}%之间`));
  } else {
    callback();
  }
};
let changeList = ref()
let regionClone = ref([])
let industryCategoryArr = ref([]) // 行业类别
let ruleForm = ref({
  region: "",
  regionClone: [],
  relationId: '',
  projectName: "",
  companyName: "",
  contacts: "",
  contactInfo: "",
  industryCategory: "",
  place: "",
  projectStartTime: "",
  projectEndTime: "",
  signingDate: "",
  contractDuration: "",
  approvalDate: "",
  contractAmount: '',
  guaranteeRatio: '',
  guaranteeAmount: '',
  projectCode: "",
  fundingSource: "",
  applyCompanyName: "",
  creditCode: "",
  legalName: "",
  legalPhone: "",
  operatorName: "",
  operatorPhone: "",
  institutionId: '',
  institutionName: "",
  institutionContact: "",
  yzm: '',
  license_certificate: [],
  apply_business_license: [],
  general_onstruction_contract: [],
  bid_win_notice: [],
  licence: [],
  qualifications: [],
  project_other: [],
  attList: []
})
let rules = ref({
  institutionName: [
    { required: true, message: '请输入承保机构', trigger: 'blur' }
  ],
  institutionContact: [
    { required: true, message: '请输入联系方式', trigger: 'blur' }
  ],
  regionClone: [
    { required: true, message: '请选择区域', trigger: 'change' }
  ],
  projectName: [
    { required: true, message: '请输入项目全称', trigger: 'blur' }
  ],
  companyName: [
    { required: true, message: '请输入建设单位', trigger: 'blur' }
  ],
  contacts: [
    { required: true, message: '请输入项目联系人', trigger: 'blur' }
  ],
  contactInfo: [
    { required: true, message: '请输入联系方式', trigger: 'blur' },
    { min: 11, max: 11, message: '法定联系电话长度为11位', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'blur' },
  ],
  industryCategory: [
    { required: true, message: '请输入行业类别', trigger: 'blur' }
  ],
  place: [
    { required: true, message: '请输入项目地点', trigger: 'blur' },
  ],
  projectStartTime: [
    { required: true, message: '请选择开工时间', trigger: 'change' },
  ],
  projectEndTime: [
    { required: true, message: '请选择完工时间', trigger: 'change' },
    { required: true, validator: validateTelTime, trigger: 'change' }
  ],
  signingDate: [
    { required: true, message: '请选择合同签订日期', trigger: 'change' },
  ],
  contractDuration: [
    { required: true, message: '请输入合同工期', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的合同工期', trigger: 'blur' },
  ],
  approvalDate: [
    { required: true, message: '请选择施工许可证批复日期', trigger: 'change' },
  ],
  contractAmount: [
    { required: true, message: '请输入合同金额', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的合同金额', trigger: 'blur' },
  ],
  guaranteeRatio: [
    { required: true, message: '请输入核定担保比例', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的核定担保比例', trigger: 'blur' },
    { required: true, validator: validateTelguaranteeRatio, trigger: 'blur' }
  ],
  guaranteeAmount: [
    { required: true, message: '请输入核定担保金额', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的核定担保金额', trigger: 'blur' },
  ],
  projectCode: [
    { required: true, message: '请输入项目编号', trigger: 'blur' },
  ],
  fundingSource: [
    { required: true, message: '请输入资金来源', trigger: 'blur' },
  ],
  applyCompanyName: [
    { required: true, message: '请输入企业名称', trigger: 'blur' }
  ],
  legalName: [
    { required: true, message: '请输入法定姓名', trigger: 'blur' }
  ],
  creditCode: [
    { required: true, message: '请输入信用代码', trigger: 'blur' },
  ],
  legalPhone: [
    { required: true, message: '请输入法定联系电话', trigger: 'blur' },
    { min: 11, max: 11, message: '法定联系电话长度为11位', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'blur' },
  ],
  operatorName: [
    { required: true, message: '请输入经办人', trigger: 'blur' }
  ],
  operatorPhone: [
    { required: true, message: '请输入经办人电话', trigger: 'blur' },
    { min: 11, max: 11, message: '经办人电话长度为11位', trigger: 'blur' },
    { required: true, validator: validateTelphone, trigger: 'blur' }
  ],
  yzm: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
  applyWay: [
    { required: true, message: '请选择缴存形式', trigger: 'change' },
  ],
  // license_certificate: [{ required: true, message: '请上传开户许可证', trigger: 'change' },],
  // apply_business_license: [{ required: true, message: '请上传营业执照', trigger: 'change' },],
  // general_onstruction_contract: [{ required: true, message: '请上传施工总承包合同', trigger: 'change' },],
  // bid_win_notice: [{ required: true, message: '请上传中标通知书', trigger: 'change' },],
  // licence: [{ required: true, message: '请上传施工许可证或开工许可证明', trigger: 'change' },],
  // qualifications: [{ required: true, message: '请上传资质', trigger: 'change' },],
  // commitment_letter: [{ required: true, message: '请上传承诺书', trigger: 'change' },],
})
let successFile = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.license_certificate) {
    ruleForm.value.license_certificate = [];
  }
  if (response.code == 200) {
    ruleForm.value.license_certificate.push({ attId: response.data.attachId, attType: 'license_certificate' })
    ruleFormRef.value.clearValidate('license_certificate');
  }
  console.log(ruleForm.value.license_certificate, 'ruleForm.value.license_certificate');
}
let successFile1 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.apply_business_license) {
    ruleForm.value.apply_business_license = [];
  }
  if (response.code == 200) {
    ruleForm.value.apply_business_license.push({ attId: response.data.attachId, attType: 'apply_business_license' })
    ruleFormRef.value.clearValidate('apply_business_license');
  }
}
let successFile2 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.general_onstruction_contract) {
    ruleForm.value.general_onstruction_contract = [];
  }
  if (response.code == 200) {
    ruleForm.value.general_onstruction_contract.push({ attId: response.data.attachId, attType: 'general_onstruction_contract' })
    ruleFormRef.value.clearValidate('general_onstruction_contract');
  }
}
let successFile3 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.bid_win_notice) {
    ruleForm.value.bid_win_notice = [];
  }
  if (response.code == 200) {
    ruleForm.value.bid_win_notice.push({ attId: response.data.attachId, attType: 'bid_win_notice' })
    ruleFormRef.value.clearValidate('bid_win_notice');
  }
}
let successFile4 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.licence) {
    ruleForm.value.licence = [];
  }
  if (response.code == 200) {
    ruleForm.value.licence.push({ attId: response.data.attachId, attType: 'licence' })
    ruleFormRef.value.clearValidate('licence');
  }
}
let successFile5 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.qualifications) {
    ruleForm.value.qualifications = [];
  }
  if (response.code == 200) {
    ruleForm.value.qualifications.push({ attId: response.data.attachId, attType: 'qualifications' })
    ruleFormRef.value.clearValidate('qualifications');
  }
}
// let successFile6 = (response, uploadFile, uploadFiles) => {
//   if (!ruleForm.value.commitment_letter) {
//     ruleForm.value.commitment_letter = [];
//   }
//   if (response.code == 200) {
//     ruleForm.value.commitment_letter.push({ attId: response.data.attachId, attType: 'commitment_letter' })
//     ruleFormRef.value.clearValidate('commitment_letter');
//   }
// }
let successFile7 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.project_other) {
    ruleForm.value.project_other = [];
  }
  if (response.code == 200) {
    ruleForm.value.project_other.push({ attId: response.data.attachId, attType: 'project_other' })
  }
}
let submitForm = async (formEl) => {
  if (!formEl) return;
  ruleForm.value.regionClone = regionClone.value
  ruleForm.value.region = ruleForm.value.regionClone.join(',')
  await formEl.validate((valid, fields) => {
    if (ruleForm.value.license_certificate && ruleForm.value.license_certificate != '') {
      ruleFormRef.value.clearValidate('license_certificate');
    }
    if (ruleForm.value.apply_business_license && ruleForm.value.apply_business_license != '') {
      ruleFormRef.value.clearValidate('apply_business_license');
    }
    if (ruleForm.value.general_onstruction_contract && ruleForm.value.general_onstruction_contract != '') {
      ruleFormRef.value.clearValidate('general_onstruction_contract');
    }
    if (ruleForm.value.bid_win_notice && ruleForm.value.bid_win_notice != '') {
      ruleFormRef.value.clearValidate('bid_win_notice');
    }
    if (ruleForm.value.licence && ruleForm.value.licence != '') {
      ruleFormRef.value.clearValidate('licence');
    }
    if (ruleForm.value.qualifications && ruleForm.value.qualifications != '') {
      ruleFormRef.value.clearValidate('qualifications');
    }
    if (ruleForm.value.institutionName && ruleForm.value.qualifications != '') {
      ruleFormRef.value.clearValidate('institutionName');
    }
    if (valid) {
      fullscreenLoading.value = true
      ruleForm.value.relationId = router.currentRoute.value.query.projectId
      const cleanArray = arr => {
        if (!Array.isArray(arr)) {
          return [];
        }
        return arr.map(item => {
          const { uid, status, name, url, ...rest } = item;
          return rest;
        })
      }
      const licenseCertificates = cleanArray(ruleForm.value.license_certificate);
      const applyBusinessLicenses = cleanArray(ruleForm.value.apply_business_license);
      const generalOnstructionContracts = cleanArray(ruleForm.value.general_onstruction_contract);
      const bidWinNotices = cleanArray(ruleForm.value.bid_win_notice);
      const licences = cleanArray(ruleForm.value.licence);
      const qualifications = cleanArray(ruleForm.value.qualifications);
      const projectOthers = cleanArray(ruleForm.value.project_other || []);
      ruleForm.value.attList = [
        ...licenseCertificates,
        ...applyBusinessLicenses,
        ...generalOnstructionContracts,
        ...bidWinNotices,
        ...licences,
        ...qualifications,
        ...projectOthers,
      ];
      submit(CaptchaKey.value, ruleForm.value.yzm, ruleForm.value).then((res) => {
        if (res.code == 200) {
          router.push('/depositPaySuccess')
          fullscreenLoading.value = false
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
  fullscreenLoading.value = false
};
let activeyzm = (formName) => {
  formName.validateField('operatorPhone', (errorMessage) => {
    if (errorMessage) {
      console.log(errorMessage);
      IsDisabled.value = false;
    } else {
      IsDisabled.value = true;
    }
  });
}
let handleRemove1 = (file, uploadFiles) => {
  ruleForm.value.license_certificate.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.license_certificate.splice(index, 1)
    }
  })
}
let handleRemove2 = (file, uploadFiles) => {
  ruleForm.value.apply_business_license.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.apply_business_license.splice(index, 1)
    }
  })
}
let handleRemove3 = (file, uploadFiles) => {
  ruleForm.value.general_onstruction_contract.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.general_onstruction_contract.splice(index, 1)
    }
  })
}
let handleRemove4 = (file, uploadFiles) => {
  ruleForm.value.bid_win_notice.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.bid_win_notice.splice(index, 1)
    }
  })
}
let handleRemove5 = (file, uploadFiles) => {
  ruleForm.value.licence.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.licence.splice(index, 1)
    }
  })
}
let handleRemove6 = (file, uploadFiles) => {
  ruleForm.value.qualifications.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.qualifications.splice(index, 1)
    }
  })
}
let handleRemove7 = (file, uploadFiles) => {
  ruleForm.value.project_other.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      ruleForm.value.project_other.splice(index, 1)
    }
  })
}
let handlePreview = (uploadFile) => {
  if (router.currentRoute.value.query.type && router.currentRoute.value.query.type == 1) {
    ElMessageBox.confirm('确认下载此附件吗？', '附件下载',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        window.open(uploadFile.url)
      }).catch(() => {
        return
      })
  } else {
    ElMessageBox.confirm('确认下载此附件吗？', '附件下载',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        window.open(uploadFile.response.data.url)
      }).catch(() => {
        return
      })
  }
}
let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length
    } totally`
  )
}
let contractAmountInput = (val) => {
  if (val && val.length > 0) {
    if (regionClone.value != '') {
      pubShow.value = false
    }
    Disabled.value = false
    pubShow.value = false
    if (ruleForm.value.guaranteeRatio && ruleForm.value.guaranteeRatio != '') {
      ruleForm.value.guaranteeAmount = (ruleForm.value.contractAmount * (ruleForm.value.guaranteeRatio * 0.01).toFixed(2))
    } else if (ruleForm.value.guaranteeAmount && ruleForm.value.guaranteeAmount != '') {
      ruleForm.value.guaranteeRatio = (ruleForm.value.guaranteeAmount / ruleForm.value.contractAmount) * 100
    }
  } else {
    pubShow.value = true
    Disabled.value = true
    ruleFormRef.value.clearValidate('guaranteeRatio');
    ruleFormRef.value.clearValidate('guaranteeAmount');
  }
}
let guaranteeRatioInput = (val) => {
  ruleForm.value.guaranteeAmount = ''
  if (ruleForm.value.guaranteeAmount == '') {
    ruleForm.value.guaranteeAmount = (ruleForm.value.contractAmount * (val * 0.01)).toFixed(2)
    ruleFormRef.value.clearValidate('guaranteeAmount');
  }
  if (ruleForm.value.guaranteeAmount == 0) {
    ruleForm.value.guaranteeAmount = ''
  }
}
let guaranteeAmountInput = (val) => {
  ruleForm.value.guaranteeRatio = ''
  if (ruleForm.value.guaranteeRatio == '') {
    ruleForm.value.guaranteeRatio = ((val / ruleForm.value.contractAmount) * 100).toFixed(2)
  }
  if (ruleForm.value.guaranteeRatio == 0) {
    ruleForm.value.guaranteeRatio = ''
  }
}
let StartTime = (time) => {
  if (time == null) {
    return ruleForm.value.contractDuration = ''
  }
  if (ruleForm.value.projectEndTime && ruleForm.value.projectEndTime != '') {
    const newTime = new Date(time);
    const projectEndTime = new Date(ruleForm.value.projectEndTime);
    const durationInMilliseconds = projectEndTime - newTime;
    const durationInDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));
    return ruleForm.value.contractDuration = durationInDays;
  }
  if (ruleForm.value.contractDuration && ruleForm.value.contractDuration != '') {
    const number = ruleForm.value.contractDuration;
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const milliseconds = number * millisecondsInDay;
    const Mydate = new Date(time.replace(' ', 'T') + 'Z');
    const millisecondsx = Mydate.getTime();
    const resultTime = millisecondsx + milliseconds
    return ruleForm.value.projectEndTime = timeZh(resultTime)
  }
}
let EndTime = (time) => {
  if (time == null) {
    return ruleForm.value.contractDuration = ''
  }
  if (ruleForm.value.projectStartTime && ruleForm.value.projectStartTime != '') {
    const newTime = new Date(time);
    const projectEndTime = new Date(ruleForm.value.projectStartTime);
    const durationInMilliseconds = newTime - projectEndTime;
    const durationInDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));
    return ruleForm.value.contractDuration = durationInDays;
  }
  if (ruleForm.value.contractDuration && ruleForm.value.contractDuration != '') {
    const number = ruleForm.value.contractDuration;
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const milliseconds = number * millisecondsInDay;
    const Mydate = new Date(time.replace(' ', 'T') + 'Z');
    const millisecondsx = Mydate.getTime();
    const resultTime = millisecondsx - milliseconds
    return ruleForm.value.projectStartTime = timeZh(resultTime)
  }
}
let Duration = (val) => {
  if (val == '') {
    ruleForm.value.projectStartTime = ''
    ruleForm.value.projectEndTime = ''
    return
  }
  if (ruleForm.value.projectStartTime && ruleForm.value.projectStartTime != '') {
    const number = val;
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const milliseconds = number * millisecondsInDay;
    const Mydate = new Date(ruleForm.value.projectStartTime.replace(' ', 'T') + 'Z');
    const millisecondsx = Mydate.getTime();
    const resultTime = millisecondsx + milliseconds
    return ruleForm.value.projectEndTime = timeZh(resultTime)
  }
  if (ruleForm.value.projectEndTime && ruleForm.value.projectEndTime != '') {
    const number = val;
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const milliseconds = number * millisecondsInDay;
    const Mydate = new Date(ruleForm.value.projectEndTime.replace(' ', 'T') + 'Z');
    const millisecondsx = Mydate.getTime();
    const resultTime = millisecondsx - milliseconds
    return ruleForm.value.projectStartTime = timeZh(resultTime)
  }
}
let timeZh = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
let beforeRemove = () => {
  return true
}
let handleChange = async (value) => {
  if (ruleForm.value.contractAmount != '') {
    pubShow.value = false
  }
  if (value && value != null) {
    show.value = false
    ruleForm.value.regionClone = value
    ruleForm.value.region = Object.values(value).join(',');
    await AlgorithmicBasis()
  } else {
    show.value = true
    pubShow.value = true
    boxShow.value = true
    ruleForm.value.applyWay = ''
    ruleForm.value.regionClone = []
    ruleForm.value.region = ''
    ruleForm.value.institutionName = ''
    ruleForm.value.institutionId = ''
    ruleForm.value.institutionContact = ''
  }
}
let applyWayChange = (val) => {
  if (val && val != '') {
    boxShow.value = false
  } else {
    boxShow.value = true
  }
  if (val == 'readyMoney' || val == 'bank') {
    selsectShow.value = true
    mrShow.value = false
  } else {
    selsectShow.value = false
    mrShow.value = true
    Underwriting()
  }
}
let Underwriting = async () => {
  await searchUnderwritingList({ regionCode: ruleForm.value.region }).then(res => {
    changeList.value = res.data
  })
}
let institutionNameChange = (val) => {
  if (val && val != '') {
    ruleFormRef.value.clearValidate('institutionName');
    changeList.value.forEach(item => {
      if (item.deptName == val) {
        ruleForm.value.institutionId = item.id
        ruleForm.value.institutionContact = item.contactInformatio
      }
    })
  } else {
    ruleForm.value.institutionId = ''
    ruleForm.value.institutionContact = ''
  }
}
let madeyzm = async () => {
  await Phonevalidate({ phone: ruleForm.value.operatorPhone }).then((res) => {
    CaptchaKey.value = res.data.id
  })
  let count = 60;
  title.value = `${count}秒后重新获取`;
  IsDisabled.value = true;
  let timer = setInterval(() => {
    count--;
    if (count > 0) {
      title.value = `${count}秒后重新获取`;
    } else {
      clearInterval(timer);
      title.value = '获取验证码';
      IsDisabled.value = false;
    }
  }, 1000);
}
let lazyTreeArr = async () => {
  await lazyTree().then((res) => {
    let slicedData = res.data.slice(1);
    treeArr.value = slicedData
    localStorage.setItem('Tree', JSON.stringify(treeArr.value))
  })
}
let applyProjectAttachMes = async () => {
  await applyState('apply_project_attach').then((res) => {
    applyProjectAttach.value = res.data
  })
}
let CompanyInformation = async () => {
  await search().then((res) => {
    ruleForm.value.applyCompanyName = res.data.fullName
    ruleForm.value.creditCode = res.data.creditCode
    ruleForm.value.legalName = res.data.legalRepresentative
    ruleForm.value.legalPhone = res.data.contactInformatio
  })
}
let LetterofCommitment = async () => {
  await applyProjectAttachList().then((res) => {
    ProjectAttachList.value = res.data
  })
}
let detailList = async () => {
  await searchSlefOrder({ projectId: router.currentRoute.value.query.projectId }).then(res => {
    IsDisabled.value = false;
    regionClone.value = res.data.orderDetail.region.split(',')
    ruleForm.value.regionClone = res.data.orderDetail.region.split(',')
    if (res.data.orderDetail.applyWay == 'readyMoney' || res.data.orderDetail.applyWay == 'bank') {
      selsectShow.value = true
    } else {
      selsectShow.value = false
    }
    ruleForm.value = res.data.orderDetail
    res.data.projectList.forEach(item => {
      if (item.attType == "apply_business_license") {
        ruleForm.value.apply_business_license = []
        ruleForm.value.apply_business_license.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "general_onstruction_contract") {
        ruleForm.value.general_onstruction_contract = []
        ruleForm.value.general_onstruction_contract.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "bid_win_notice") {
        ruleForm.value.bid_win_notice = []
        ruleForm.value.bid_win_notice.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "licence") {
        ruleForm.value.licence = []
        ruleForm.value.licence.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "qualifications") {
        ruleForm.value.qualifications = []
        ruleForm.value.qualifications.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "license_certificate") {
        ruleForm.value.license_certificate = []
        ruleForm.value.license_certificate.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "project_other") {
        ruleForm.value.project_other = []
        ruleForm.value.project_other.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      }
    })
  })
}
let AlgorithmicBasis = async () => {
  await detailByRegion({ region: ruleForm.value.region }).then(res => {
    console.log(res, 'res');

    AlgorithmicBasisList.value = res.data
    maxRate.value = res.data?.maxRate ?? 100;
    minRate.value = res.data?.minRate ?? 0;
  })
}
// 获取行业内类别
let getIndustryCategory = async () => {
  await reqIndustryCategory('industry_category').then((res) => {
    if(res.code == 200 ) {
       industryCategoryArr.value= res.data || []
    }
  })
}
onMounted(async () => {
  if (router.currentRoute.value.query.type && router.currentRoute.value.query.type == 1) {
    await detailList()
    await Underwriting()
    show.value = false
    mrShow.value = false
    boxShow.value = false
    changeList.value.forEach(item => {
      if (item.deptName == ruleForm.value.institutionName) {
        ruleForm.value.institutionId = item.id
      }
    })
  }

  await LetterofCommitment()
  await applyProjectAttachMes()
  await CompanyInformation()
  await getIndustryCategory()
  if (localStorage.getItem('Tree')) {
    treeArr.value = JSON.parse(localStorage.getItem('Tree'))
  } else {
    await lazyTreeArr()
  }
})
</script>
<style scoped>
::v-deep .el-select__wrapper {
  height: 100%;
}
::v-deep .upload-demo {
  width: 100%;
}
::v-deep .el-form-item--default .el-form-item__content {
  width: 260px !important;
}
::v-deep .el-input__wrapper {
  height: 40px !important;
}
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 30px;
}
::v-deep .el-form-item--default .el-form-item__label {
  height: 40px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
::v-deep
  .el-form--default.el-form--label-top
  .el-form-item
  .el-form-item__label {
  line-height: 22px;
  margin-bottom: 8px;
  width: 302px;
  display: flex;
  justify-content: flex-start;
}
.annex ::v-deep .el-form-item__label {
  white-space: nowrap;
  justify-content: flex-start;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
.el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(182, 186, 210, 1);
  white-space: nowrap;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.tp150 {
  margin-top: 150px;
}
.SignUpForms-box {
  padding-top: 135px;
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 465px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -105px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
</style>
