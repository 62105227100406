<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="title-img">
          <div style="font-size: 42px;font-weight: 500;color: rgba(0, 82, 217, 1);position: relative;">
            <div>智能投保平台</div>
            <div style="width: 380px;position: absolute;bottom: -40px;left: -60px;"><img src="./../static/zntb.png" alt=""></div>
          </div>
          <div style="font-size: 24px;font-weight: 400;color: rgba(102, 102, 102, 1);margin-top:10px;">全流程在线办理 极速出函</div>
        </div>
        <div class="title-tip w1300">
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item,index) in titleArr" :key="item.id" style="width: calc(100% / 7);display: flex;flex-direction: column;align-items: center;">
              <div style="width: 75px;height: 52px;padding-left: 12px;"><img :src="item.url" alt="" style="object-fit:none;margin-left: 5px;" :style="index==2?'margin-left: 15px;':''"></div>
              <div style="display: flex;align-items: center;padding-top:15px;">
                <div style="width: 83px;height: 0px;border: 1px dashed rgb(42, 123, 234);" :style="index>1?'border: 1px dashed rgb(166,166,166);':''"></div>
                <div style="width: 16px;height: 16px;background: rgb(42, 123, 234);border-radius:50%;" :style="index>1?'background: rgb(215, 215, 215);':''"></div>
                <div style="width: 83px;height: 0px;" :style="index<1?'border:1px dashed rgb(42, 123, 234);':'border: 1px dashed rgb(166,166,166);'"></div>
              </div>
              <div style="padding-top:15px;font-size: 18px;color: rgb(69, 87, 124);" :style="index>1?'color: rgb(215, 215, 215);':''">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="SignUpForms">
        <div class="w1300 SignUpForms-box">
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">投保信息</div>
              </div>
              <div style="display: flex;justify-content: space-between;align-items:center;margin-top: 20px;">
                <div style="width: 218px;"></div>
                <div style="font-size: 18px;font-weight: 700;color: #000;">承保单位：{{MyUnderwritingName}}</div>
                <div style="display: flex;">
                  <div class="btn-false" @click.stop="guideline(UnderwritingList.guideline)">准入准则</div>
                  <div class="btn-false" @click.stop="notice(UnderwritingList.notice)">投保须知</div>
                  <div class="btn-false" @click.stop="clause(UnderwritingList.clause)">保函条款</div>
                </div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="localMesage" :rules="rules" label-width="120" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="投保人类型:" prop="bidType">
                    <el-input placeholder="请输入投保人类型" v-model="localMesage.bidType" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目全称:" prop="projectName">
                    <el-input :disabled='true' placeholder="请输入项目全称" v-model="localMesage.projectName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目编号:" prop="projectCode">
                    <el-input :disabled='true' placeholder="请输入项目编号" v-model="localMesage.projectCode" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元):" prop="contractAmount">
                    <el-input :disabled='true' placeholder="请输入合同金额" v-model="localMesage.contractAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(天):" prop="contractDuration">
                    <el-input :disabled='true' placeholder="请输入合同工期" v-model="localMesage.contractDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="企业资质:" prop="qualifications">
                    <el-input placeholder="请输入企业资质" v-model="localMesage.qualifications" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间:" prop="projectStartTime">
                    <el-date-picker v-model="localMesage.projectStartTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择开工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="完工时间:" prop="projectEndTime">
                    <el-date-picker v-model="localMesage.projectEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择完工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="工期(天):" prop="duration">
                    <el-input placeholder='请输入工期' v-model="localMesage.duration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="核定担保金额:" prop="guaranteeAmount">
                    <el-input :disabled='true' placeholder='请输入核定担保金额' v-model="localMesage.guaranteeAmount" style="width: 260px;height:40px;">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="核定生效日期:" prop="verifyTime">
                    <el-date-picker :disabled='true' v-model="localMesage.verifyTime" type="date" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择核定生效日期" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="起保时间:" prop="startTime" style="position: relative">
                    <el-date-picker :disabled-date="disabledDate" :clearable='false' v-model="localMesage.startTime" type="datetime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择起保时间" size='large' style="width: 260px;" @change="MyStartTime(localMesage.startTime)" />
                    <div style="position: absolute;bottom:-25px;left:0;color:#f56c6c;">起保时间为缴费截止日期，请及时缴费!</div>
                  </el-form-item>
                  <el-form-item label="保障天数(日历天):" prop="depositDays" style="position: relative">
                    <el-input v-model="localMesage.depositDays" placeholder="请输入保障天数" style="width: 260px;height:40px;" @input="MyTime(localMesage.depositDays)" />
                    <div style="position: absolute;bottom:-25px;left:0;color:#f56c6c;">投保期限不超过5年，未满1年按1年算!</div>
                  </el-form-item>
                  <el-form-item label="终保时间:" prop="endTime">
                    <el-date-picker :disabled='true' v-model="localMesage.endTime" type="datetime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择终保时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="保函费率:" prop="premiumRate" style="position: relative">
                    <el-input v-model="localMesage.premiumRate" placeholder="请输入保函费率" style="width: 260px;height:40px;" @input="bxfl(localMesage.premiumRate)">
                      <template #append>%</template>
                    </el-input>
                    <div style="position: absolute;bottom:-30px;left:0;color:#f56c6c;line-height: 1;">费率期间：{{minRate}}%~{{maxRate}}%,保险费率和保费金额以承保机构最终数据为准!</div>
                  </el-form-item>
                  <el-form-item label="保费金额:" prop="premiumAmount">
                    <el-input :disabled='true' v-model="localMesage.premiumAmount" placeholder="请输入保费金额" style="width: 260px;height:40px;">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <div style="margin-top:50px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                      <div class="number-tip">附件</div>
                    </div>
                    <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap: wrap;">
                      <div class="annex">

                        <el-form-item label="工商营业执照：" prop="name">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in apply_business_license" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="施工总承包合同：" prop="name">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in general_onstruction_contract" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="项目中标通知书：" prop="name">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in bid_win_notice" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="施工许可证或开工许可证明：" prop="name">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in licence" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="建筑企业资质证明：" prop="name">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in qualifications" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                      <!-- <div class="annex">
                        <el-form-item label="承诺书:" prop="name" style="width: 302px;">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in commitment_letter" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div> -->
                      <div class="annex">
                        <el-form-item prop="commitment_letter" style="display: flex;flex-direction:column;">
                          <template #label>
                            <span>承诺书<span style="color:rgb(212, 48, 48)">(请在下载承诺书文件签字盖章后上传)</span>:</span>
                          </template>
                          <el-upload :file-list="localMesage.commitment_letterClone" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove1" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile">
                            <template #trigger>
                              <el-button type="primary" :icon="Upload">上传附件</el-button>
                            </template>
                            <template #default>
                              <el-button type="primary" style="margin-top:-7px;margin-left:10px;" :icon="Download" @click="DownloadList">
                                下载承诺书
                              </el-button>
                            </template>
                          </el-upload>
                        </el-form-item>
                      </div>
                      <!-- <div class="annex">
                        <el-form-item label="审计报告：" prop="audit_report">
                          <el-upload v-model:file-list="localMesage.audit_reportClone" class="upload-demo" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :headers="headers" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile1">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div> -->
                      <div class="annex">
                        <el-form-item label="财务报表：" prop="financial_statements">
                          <el-upload v-model:file-list="localMesage.financial_statementsClone" class="upload-demo" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :headers="headers" :on-remove="handleRemove2" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile2">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="其他附件：">
                          <el-upload v-model:file-list="localMesage.apply_info_otherClone" :headers="headers" class="upload-demo" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove3" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile3">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                        <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in other" :key="item.id" @click="downLoadeFile(item.name)">
                          {{item.originalName}}
                        </div>
                      </div>
                      <div class="annex" style="visibility: hidden;">
                        <el-form-item>
                          <el-upload :headers="headers" class="upload-demo" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile3">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;padding: 80px 0 50px 0;">
              <el-checkbox v-model="checked">已阅读投保须知和保函条款并同意授权</el-checkbox>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;padding:0;">
              <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="submitForm(ruleFormRef)" style="width: 150px;height: 40px;font-size: 14px;">提交申请</el-button>
            </div>
          </div>
        </div>
      </div>
      <el-dialog v-model="dialogVisible1" title="准入准则" width="50%">
        <div v-html="guidelineList"></div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible1 = false" size="large">确定</el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog v-model="dialogVisible2" title="投保须知" width="50%">
        <div v-html="noticeList"></div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible2 = false" size="large">确定</el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog v-model="dialogVisible3" title="保函条款" width="50%">
        <div v-html="clauseList"></div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible3 = false" size="large">确定</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import { Upload, Download } from '@element-plus/icons-vue'
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { searchProject, applyInfoSubmit, applyProjectAttachList, searchApply, searchUnderwriting, detailRateConfig } from "@/api/index.js"
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let MyUnderwritingName = ref()
let checked = ref(true)
let ProjectAttachList = ref()
let disabledDate = (time) => {
  return time.getTime() < Date.now() - 8.64e7
}
let maxRate = ref()
let minRate = ref()
let minCost = ref() // 最低收费
let dialogVisible1 = ref(false)
let dialogVisible2 = ref(false)
let dialogVisible3 = ref(false)
let guidelineList = ref()
let noticeList = ref()
let clauseList = ref()
let fullscreenLoading = ref(false)
let localMesage = ref({
  endTime: "",
  institutionId: '',
  projectId: '',
  effectiveDate: '',
  audit_report: [],
  financial_statements: [],
  apply_info_other: [],
  commitment_letter: [],
  commitment_letterClone: [],
  audit_reportClone: [],
  financial_statementsClone: [],
  apply_info_otherClone: [],
  attList: []
})
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let apply_business_license = ref([])
let general_onstruction_contract = ref([])
let bid_win_notice = ref([])
let licence = ref([])
let qualifications = ref([])
let commitment_letter = ref([])
let financial_statements = ref([])
let UnderwritingList = ref({})
let other = ref([])
let titleArr = ref([
  // { id: 1, url: require('./../static/step1.png'), title: '项目信息申报' },
  // { id: 3, url: require('./../static/step3.png'), title: '保证金核定' },
  // { id: 5, url: require('./../static/ico-04.png'), title: '三制落实' },
  // { id: 6, url: require('./../static/ico-05.png'), title: '季度申报' },
  // { id: 7, url: require('./../static/ico-02.png'), title: '工资监管' },
  { id: 1, url: require('./../static/step1.png'), title: '项目申请' },
  { id: 3, url: require('./../static/ico-02.png'), title: '项目审核' },
  { id: 5, url: require('./../static/ico-03.png'), title: '保证申请' },
  { id: 6, url: require('./../static/ico-02.png'), title: '机构审核' },
  { id: 7, url: require('./../static/ico-04.png'), title: '费用缴纳' },
  { id: 8, url: require('./../static/ico-05.png'), title: '出纳' },
  { id: 9, url: require('./../static/ico-02.png'), title: '人社审核' },
])
let router = useRouter()
let formSize = ref('default')
let ruleFormRef = ref()
let sj = ref()
let successFile = (response, uploadFile, uploadFiles) => {
  if (!localMesage.value.commitment_letter) {
    localMesage.value.commitment_letter = [];
  }
  if (response.code == 200) {
    localMesage.value.commitment_letter.push({ attId: response.data.attachId, attType: 'commitment_letter' })
    ruleFormRef.value.clearValidate('commitment_letter');
  }
}
let successFile1 = (response, uploadFile, uploadFiles) => {
  if (!localMesage.value.audit_report) {
    localMesage.value.audit_report = [];
  }
  if (response.code == 200) {
    localMesage.value.audit_report.push({ attId: response.data.attachId, attType: 'audit_report' })
    ruleFormRef.value.clearValidate('audit_report');
  }
}
let successFile2 = (response, uploadFile, uploadFiles) => {
  if (!localMesage.value.financial_statements) {
    localMesage.value.financial_statements = [];
  }
  if (response.code == 200) {
    localMesage.value.financial_statements.push({ attId: response.data.attachId, attType: 'financial_statements' })
    ruleFormRef.value.clearValidate('financial_statements');
  }
}
let successFile3 = (response, uploadFile, uploadFiles) => {
  if (!localMesage.value.apply_info_other) {
    localMesage.value.apply_info_other = [];
  }
  if (response.code == 200) {
    localMesage.value.apply_info_other.push({ attId: response.data.attachId, attType: 'apply_info_other' })
  }
}
let validateTelTime = (rule, value, callback) => {
  if (value < localMesage.value.projectStartTime) {
    callback(new Error('完工时间不能小于开工时间'));
  } else {
    callback();
  }
}
let validateTelNumber = (rule, value, callback) => {
  if (value < Number(minRate.value) || value > Number(maxRate.value)) {
    callback(new Error(''));
  } else {
    callback();
  }
}
let TimeLength = (rule, value, callback) => {
  if (value < 1 || value > 1825) {
    callback(new Error(''));
  } else {
    callback();
  }
}
let rules = ref({
  audit_report: [{ required: true, message: '请上传审计报告', trigger: 'change' }],
  financial_statements: [{ required: true, message: '请上传财务报表', trigger: 'change' }],
  premiumAmount: [
    { required: true, message: '请输入保费金额', trigger: 'blur' },
  ],
  premiumRate: [
    { required: true, message: '', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '', trigger: 'blur' },
    { required: true, validator: validateTelNumber, trigger: 'blur' },
  ],
  endTime: [
    { required: true, message: '请输入终保时间', trigger: 'blur' },
  ],
  depositDays: [
    { required: true, message: '', trigger: 'blur' },
    { required: true, validator: TimeLength, trigger: 'blur' },
    { pattern: /^[1-9]\d*$/, message: '', trigger: 'blur' },
  ],
  startTime: [
    { required: true, message: '', trigger: 'blur' },
  ],
  verifyTime: [
    { required: true, message: '请选择核定生效日期', trigger: 'blur' },
  ],
  duration: [
    { required: true, message: '请输入工期', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的工期', trigger: 'blur' },
  ],
  qualifications: [
    { required: true, message: '请输入企业资质', trigger: 'blur' }
  ],
  bidType: [
    { required: true, message: '请输入投保人类型', trigger: 'blur' }
  ],
  projectName: [
    { required: true, message: '请输入项目全称', trigger: 'blur' }
  ],
  projectStartTime: [
    { required: true, message: '请选择开工时间', trigger: 'blur' },
  ],
  projectEndTime: [
    { required: true, message: '请选择完工时间', trigger: 'blur' },
    { required: true, validator: validateTelTime, trigger: 'blur' }
  ],
  contractDuration: [
    { required: true, message: '请输入合同工期', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的合同工期', trigger: 'blur' },
  ],
  contractAmount: [
    { required: true, message: '请输入合同金额', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的合同金额', trigger: 'blur' },
  ],
  guaranteeAmount: [
    { required: true, message: '请输入核定担保金额', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的核定担保金额', trigger: 'blur' },
  ],
  projectCode: [
    { required: true, message: '请输入项目编号', trigger: 'blur' },
  ],
})
let downLoadeFile = (url) => {
  window.open(getDynamicImageUrl(url))
}
let getDynamicImageUrl = (url) => {
  let currentUrl = window.location.href;
  let parts = currentUrl.split('/');
  let baseUrl = `${parts[0]}//${parts[2]}`;
  return `${baseUrl}` + '/' + `${url}`;
}
let submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (localMesage.value.commitment_letter && localMesage.value.commitment_letter != '') {
      ruleFormRef.value.clearValidate('commitment_letter');
    }
    if (localMesage.value.financial_statements && localMesage.value.financial_statements != '') {
      ruleFormRef.value.clearValidate('financial_statements');
    }
    if (!checked.value) {
      ElMessage.warning('请阅读并勾选投保须知和保函条款');
      return
    }
    if (valid) {
      fullscreenLoading.value = true
      localMesage.value.institutionId = router.currentRoute.value.query.ids
      localMesage.value.projectId = router.currentRoute.value.query.projectId
      localMesage.value.effectiveDate = localMesage.value.verifyTime
      let otherInfoValues = localMesage.value.apply_info_other ? Object.values(localMesage.value.apply_info_other) : [];
      let commitment_letter = localMesage.value.commitment_letter ? Object.values(localMesage.value.commitment_letter) : [];
      localMesage.value.attList = [...commitment_letter, ...localMesage.value.financial_statements, ...otherInfoValues]
      applyInfoSubmit(localMesage.value).then((res) => {
        if (res.code == 200) {
          router.push('/applicationInsuranceSuccess')
          fullscreenLoading.value = false
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
  fullscreenLoading.value = false
}
let handleRemove1 = (file, uploadFiles) => {
  localMesage.value.commitment_letterClone.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.commitment_letterClone.splice(index, 1)
      localMesage.value.commitment_letter.splice(index, 1)
    }
  })
  localMesage.value.commitment_letter.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.commitment_letter.splice(index, 1)
    }
  })
}
let handleRemove2 = (file, uploadFiles) => {
  localMesage.value.financial_statementsClone.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.financial_statementsClone.splice(index, 1)
      localMesage.value.financial_statements.splice(index, 1)
    }
  })
  localMesage.value.financial_statements.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.financial_statements.splice(index, 1)
    }
  })
}
let handleRemove3 = (file, uploadFiles) => {
  localMesage.value.apply_info_otherClone.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.apply_info_otherClone.splice(index, 1)
      localMesage.value.apply_info_other.splice(index, 1)
    }
  })
  localMesage.value.apply_info_other.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.apply_info_other.splice(index, 1)
    }
  })
}
let handlePreview = (uploadFile) => {
  ElMessageBox.confirm('确认下载此附件吗？', '附件下载',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      window.open(uploadFile.response.data.link)
    }).catch(() => {
      return
    })
}
let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length
    } totally`
  )
}
let beforeRemove = (uploadFile, uploadFiles) => {
  return true
}
let GetProject = async () => {
  await searchProject({ id: router.currentRoute.value.query.projectId }).then(res => {
    delete res.data.id;
    localMesage.value = res.data
    res.data.projectList.forEach(item => {
      if (item.attType == "apply_business_license") {
        apply_business_license.value.push(item)
      } else if (item.attType == "general_onstruction_contract") {
        general_onstruction_contract.value.push(item)
      } else if (item.attType == "bid_win_notice") {
        bid_win_notice.value.push(item)
      } else if (item.attType == "licence") {
        licence.value.push(item)
      } else if (item.attType == "qualifications") {
        qualifications.value.push(item)
      } else if (item.attType == "financial_statements") {
        localMesage.value.financial_statementsClone = []
        localMesage.value.financial_statementsClone.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
        financial_statements.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "commitment_letter") {
        localMesage.value.commitment_letterClone = []
        localMesage.value.commitment_letterClone.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
        commitment_letter.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "project_other") {
        other.value.push(item)
      }
    })
  })
}
let LetterofCommitment = async () => {
  await applyProjectAttachList().then((res) => {
    ProjectAttachList.value = res.data
  })
}
let DownloadList = () => {
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const baseUrl = `${parts[0]}//${parts[2]}`;
  window.open(`${baseUrl}` + '/' + `${router.currentRoute.value.query.commitmentLetter}`)
}
let bxfl = (val) => {
  if (localMesage.value.startTime != null && localMesage.value.depositDays != null) {
    var startDate = new Date(localMesage.value.startTime)
    var endDate = new Date(localMesage.value.endTime)
    var timeDifference = endDate.getTime() - startDate.getTime()
    var daysDifference = timeDifference / (1000 * 60 * 60 * 24)
    if (daysDifference % 365 != 0) {
      localMesage.value.premiumAmount = (val * localMesage.value.guaranteeAmount * Math.ceil(daysDifference / 365) * 0.01).toFixed(2)
    } else {
      localMesage.value.premiumAmount = ((val * localMesage.value.guaranteeAmount) * daysDifference / 365 * 0.01).toFixed(2)
    }
    if (localMesage.value.premiumAmount == 0) {
      localMesage.value.premiumAmount = ''
    }
  } else {
    localMesage.value.premiumAmount = ''
  }
  if (localMesage.value.depositDays != null) {
    if (localMesage.value.depositDays <= 365) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * val * 0.01 * 1).toFixed(2)
    } else if (localMesage.value.depositDays <= 730) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * val * 0.01 * 2).toFixed(2)
    } else if (localMesage.value.depositDays <= 1095) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * val * 0.01 * 3).toFixed(2)
    } else if (localMesage.value.depositDays <= 1460) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * val * 0.01 * 4).toFixed(2)
    } else if (localMesage.value.depositDays <= 1825) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * val * 0.01 * 5).toFixed(2)
    } else {
      return
    }
  }
  if (localMesage.value.premiumAmount == 0) {
    localMesage.value.premiumAmount = ''
  } else {
    // 计算金额与最低收费哪个大取哪个
    localMesage.value.premiumAmount = Number(localMesage.value.premiumAmount)>Number(minCost.value) ? localMesage.value.premiumAmount : minCost.value
  }
}
let MyStartTime = (val) => {
  if (localMesage.value.startTime) ruleFormRef.value.clearValidate('startTime');
  if (val == '') localMesage.value.endTime = ''
  if (localMesage.value.depositDays <= 364) {
    sj.value = 365
    if (localMesage.value.depositDays && localMesage.value.depositDays != '') {
      let startTimeStamp = Date.parse(val);
      let depositMilliseconds = sj.value * (24 * 60 * 60 * 1000);
      let value = startTimeStamp + depositMilliseconds;
      let endDate = new Date(value);
      let year = endDate.getFullYear();
      let month = ('0' + (endDate.getMonth() + 1)).slice(-2);
      let day = ('0' + endDate.getDate()).slice(-2);
      let hours = ('0' + endDate.getHours()).slice(-2);
      let minutes = ('0' + endDate.getMinutes()).slice(-2);
      let seconds = ('0' + endDate.getSeconds()).slice(-2);
      let formattedEndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      localMesage.value.endTime = formattedEndTime
    }
  } else {
    if (localMesage.value.depositDays && localMesage.value.depositDays != '') {
      let startTimeStamp = Date.parse(val);
      let depositMilliseconds = localMesage.value.depositDays.value * (24 * 60 * 60 * 1000);
      let value = startTimeStamp + depositMilliseconds;
      let endDate = new Date(value);
      let year = endDate.getFullYear();
      let month = ('0' + (endDate.getMonth() + 1)).slice(-2);
      let day = ('0' + endDate.getDate()).slice(-2);
      let hours = ('0' + endDate.getHours()).slice(-2);
      let minutes = ('0' + endDate.getMinutes()).slice(-2);
      let seconds = ('0' + endDate.getSeconds()).slice(-2);
      let formattedEndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      localMesage.value.endTime = formattedEndTime
    }
  }

}
let MyTime = (val) => {
  if (val == '') {
    localMesage.value.endTime = '';
    localMesage.value.premiumAmount = '';
    return
  }
  if (localMesage.value.depositDays <= 364) {
    sj.value = 365
    if (localMesage.value.startTime && localMesage.value.startTime != '') {
      let startTimeStamp = Date.parse(localMesage.value.startTime);
      let value = startTimeStamp + sj.value * (24 * 60 * 60 * 1000)
      let endDate = new Date(value);
      let year = endDate.getFullYear();
      let month = ('0' + (endDate.getMonth() + 1)).slice(-2);
      let day = ('0' + endDate.getDate()).slice(-2);
      let hours = ('0' + endDate.getHours()).slice(-2);
      let minutes = ('0' + endDate.getMinutes()).slice(-2);
      let seconds = ('0' + endDate.getSeconds()).slice(-2);
      let formattedEndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      localMesage.value.endTime = formattedEndTime
    }
  } else {
    if (localMesage.value.startTime && localMesage.value.startTime != '') {
      let startTimeStamp = Date.parse(localMesage.value.startTime);
      let value = startTimeStamp + val * (24 * 60 * 60 * 1000)
      let endDate = new Date(value);
      let year = endDate.getFullYear();
      let month = ('0' + (endDate.getMonth() + 1)).slice(-2);
      let day = ('0' + endDate.getDate()).slice(-2);
      let hours = ('0' + endDate.getHours()).slice(-2);
      let minutes = ('0' + endDate.getMinutes()).slice(-2);
      let seconds = ('0' + endDate.getSeconds()).slice(-2);
      let formattedEndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      localMesage.value.endTime = formattedEndTime
    }
  }
  if (localMesage.value.premiumRate != null) {
    if (localMesage.value.depositDays <= 365) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * localMesage.value.premiumRate * 0.01 * 1).toFixed(2)
    } else if (localMesage.value.depositDays <= 730) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * localMesage.value.premiumRate * 0.01 * 2).toFixed(2)
    } else if (localMesage.value.depositDays <= 1095) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * localMesage.value.premiumRate * 0.01 * 3).toFixed(2)
    } else if (localMesage.value.depositDays <= 1460) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * localMesage.value.premiumRate * 0.01 * 4).toFixed(2)
    } else if (localMesage.value.depositDays <= 1825) {
      localMesage.value.premiumAmount = (localMesage.value.guaranteeAmount * localMesage.value.premiumRate * 0.01 * 5).toFixed(2)
    } else {
      return
    }
  }
  if (localMesage.value.premiumAmount == 0) {
    localMesage.value.premiumAmount = ''
  } else {
    localMesage.value.premiumAmount = Number(localMesage.value.premiumAmount)>Number(minCost.value) ? localMesage.value.premiumAmount : minCost.value
  }
}
let detailList = async () => {
  await searchApply({ id: router.currentRoute.value.query.relationId }).then(res => {
    localMesage.value = res.data
    res.data.attList.forEach(item => {
      if (item.attType == "apply_business_license") {
        apply_business_license.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "general_onstruction_contract") {
        general_onstruction_contract.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "bid_win_notice") {
        bid_win_notice.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "licence") {
        licence.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "qualifications") {
        qualifications.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "commitment_letter") {
        localMesage.value.commitment_letterClone = []
        localMesage.value.commitment_letterClone.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
        commitment_letter.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "financial_statements") {
        localMesage.value.financial_statementsClone = []
        localMesage.value.financial_statementsClone.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
        financial_statements.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      } else if (item.attType == "project_other") {
        other.value.push({ attId: item.attId, attType: item.attType, name: item.originalName, url: item.name })
      }
    })
  })
}
let Underwriting = async () => {
  await searchUnderwriting({ id: router.currentRoute.value.query.notice }).then(res => {
    UnderwritingList.value = res.data
  })
}
let guideline = (value) => {
  guidelineList.value = value
  dialogVisible1.value = true
}
let notice = (value) => {
  noticeList.value = value
  dialogVisible2.value = true
}
let clause = (value) => {
  clauseList.value = value
  dialogVisible3.value = true
}
let CalculationMethod = async () => {
  await detailRateConfig({ deptId: router.currentRoute.value.query.ids }).then(res => {
    maxRate.value = res.data?.maxRate ?? 100;
    minRate.value = res.data?.minRate ?? 0;
    minCost.value = res.data?.minCost ?? 0;
  })
}
onMounted(async () => {
  await CalculationMethod()
  await Underwriting()
  await LetterofCommitment()
  await GetProject()
  // if (router.currentRoute.value.query.nature == 2) {
  //   await detailList()
  // } else {
  //   await LetterofCommitment()
  //   await GetProject()
  // }
  MyUnderwritingName.value = router.currentRoute.value.query.UnderwritingName
})
</script>
<style scoped>
.annex ::v-deep .el-form-item {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.annex ::v-deep .el-form-item--default .el-form-item__label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
::v-deep .upload-demo {
  width: 100%;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
::v-deep .el-checkbox.el-checkbox--small .el-checkbox__label {
  font-size: 16px;
  color: #000;
}
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
::v-deep .el-form-item--default .el-form-item__label {
  height: 40px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
::v-deep
  .el-form--default.el-form--label-top
  .el-form-item
  .el-form-item__label {
  line-height: 22px;
  margin-bottom: 8px;
  width: 302px;
  display: flex;
  justify-content: flex-start;
}
.btn-false {
  border-radius: 6px;
  background: rgb(236, 245, 255);
  border: 1px solid rgb(59, 133, 244);
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  font-size: 12px;
  color: rgba(59, 133, 244, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 3px 8px;
  margin-left: 10px;
}
.btn-false:nth-child(1) {
  margin-left: 0;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
.el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(182, 186, 210, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.tp150 {
  margin-top: 150px;
}
.SignUpForms-box {
  padding-top: 135px;
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -105px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
</style>
