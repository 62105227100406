<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="w1300 SignUpForms-box">
          <div style="display: flex;justify-content: space-between;align-items: center;margin:50px 0 30px 0;">
            <div style="display: flex;align-items: center;cursor: pointer;" @click="goBack">
              <div><img src="./../static/back.png" alt="" style="width: 13px;height: 14px;"></div>
              <div style="font-size: 16px;color: rgb(0, 82, 217);margin-left:5px;">返回订单列表</div>
            </div>
            <div style="font-size: 24px;font-weight: 500;color: #000;">保证金核交信息</div>
            <div style="width: 140px;"></div>
          </div>
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">项目基本信息</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" label-width="130" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="所属区域：">
                    <el-input :disabled="true" v-model="ruleForm.regionName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目名称：">
                    <el-input :disabled="true" v-model="ruleForm.projectName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="建设单位：">
                    <el-input :disabled="true" v-model="ruleForm.companyName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目联系人：">
                    <el-input :disabled="true" v-model="ruleForm.contacts" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="联系方式：">
                    <el-input :disabled="true" v-model="ruleForm.contactInfo" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="行业类别：">
                    <el-select :disabled="true" v-model="ruleForm.industryCategory" placeholder="请选择行业类别" style="width: 260px;height:40px;">
                        <el-option v-for="(item,index) in industryCategoryArr" :key="item.id+index" :label="item.dictValue" :value="item.dictKey" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="项目地点：">
                    <el-input :disabled="true" v-model="ruleForm.place" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元)：">
                    <el-input :disabled="true" v-model="ruleForm.contractAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间：">
                    <el-input :disabled="true" v-model="ruleForm.projectStartTime" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="完工时间：">
                    <el-input :disabled="true" v-model="ruleForm.projectEndTime" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同签订日期：">
                    <el-input :disabled="true" v-model="ruleForm.signingDate" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(元)：">
                    <el-input :disabled="true" v-model="ruleForm.contractDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="施工许可证(或开工报告)批复日期：">
                    <el-input :disabled="true" v-model="ruleForm.approvalDate" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="资金来源：">
                    <el-input :disabled="true" v-model="ruleForm.fundingSource" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="缴存形式:">
                    <el-select v-model="ruleForm.applyWay" placeholder="请选择缴存形式" disabled style="width: 260px;height:40px;">
                      <el-option label="现金缴存" value="readyMoney" />
                      <el-option label="银行保函" value="bank" />
                      <el-option label="保证保险" value="bond" />
                      <el-option label="担保保函" value="guarantee" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="承保机构:">
                    <el-input disabled v-model="ruleForm.institutionName" placeholder="请输入承保机构" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="联系方式:">
                    <el-input disabled v-model="ruleForm.institutionContact" placeholder="请输入联系方式" style="width: 260px;height:40px;" />
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div style="margin-top:50px;">
              <div class="img-tip">
                <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                <div class="number-tip">投保企业信息</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" label-width="130" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="企业名称：">
                    <el-input :disabled="true" v-model="ruleForm.companyName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="信用代码：" prop="password">
                    <el-input :disabled="true" v-model="ruleForm.creditCode" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="法定姓名：" prop="surePassword">
                    <el-input :disabled="true" v-model="ruleForm.legalName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="法人联系电话：">
                    <el-input :disabled="true" v-model="ruleForm.legalPhone" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="经办人：" prop="yzm">
                    <el-input :disabled="true" v-model="ruleForm.operatorName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="经办人电话：" prop="yzm">
                    <el-input :disabled="true" v-model="ruleForm.operatorPhone" style="width: 260px;height:40px;" />
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div style="margin-top:50px;">
              <div class="img-tip">
                <div class="number-img"><img src="./../static/03-img.png" alt=""></div>
                <div class="number-tip">附件</div>
              </div>
              <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap:wrap;">
                <div class="annex">
                  <el-form-item label="工商营业执照：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in apply_business_license" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="施工总承包合同：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in general_onstruction_contract" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="项目中标通知书：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in bid_win_notice" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="施工许可证或开工许可证明：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in licence" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="建筑企业资质证明：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in qualifications" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="开户许可证:" style="width: 302px;">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in license_certificate" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="其他附件：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in other" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="margin-top:50px;" v-if="ratifying!=null&&ratifying.name&&ratifying.name!=''">
              <div class="img-tip">
                <div class="number-img"><img src="./../static/04-img.png" alt=""></div>
                <div class="number-tip">核准书</div>
              </div>
              <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap:wrap;">
                <div class="annex">
                  <el-form-item label="核准书：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" @click="downLoadeFile(ratifying.name)">
                      {{ratifying.originalName}}
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w1300 SignUpForms-box" v-if="ruleForm.applyId&&ruleForm.applyId>0&&ruleForm.applyWay!='readyMoney'&&ruleForm.applyWay!='bank'">
          <div style="display: flex;justify-content:center;align-items: center;margin:50px 0 30px 0;">
            <div style="font-size: 24px;font-weight: 500;color: #000;">投保申请信息</div>
          </div>
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">投保信息</div>
              </div>
              <div style="display: flex;justify-content: space-between;align-items:center;margin-top: 20px;">
                <div style="width: 218px;"></div>
                <div style="font-size: 18px;font-weight: 700;color: #000;">承保单位：{{institutionName}}</div>
                <div style="display: flex;">
                  <div class="btn-false" @click.stop="guideline(UnderwritingList.guideline)">准入准则</div>
                  <div class="btn-false" @click.stop="notice(UnderwritingList.notice)">投保须知</div>
                  <div class="btn-false" @click.stop="clause(UnderwritingList.clause)">保函条款</div>
                </div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" :model="ruleForm" label-width="130" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="投保人类型：">
                    <el-input :disabled="true" v-model="ruleForm.bidType" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目全称：">
                    <el-input :disabled="true" v-model="ruleForm.applyProjectName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目编号：">
                    <el-input :disabled="true" v-model="ruleForm.applyProjectCode" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元)：">
                    <el-input :disabled="true" v-model="ruleForm.applyContractAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(天)：">
                    <el-input :disabled="true" v-model="ruleForm.applyDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="企业资质：">
                    <el-input :disabled="true" v-model="ruleForm.applyQualifications" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间：">
                    <el-input :disabled="true" v-model="ruleForm.applyProjectStartTime" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="完工时间：">
                    <el-input :disabled="true" v-model="ruleForm.applyProjectEndTime" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="工期(天)：">
                    <el-input :disabled="true" v-model="ruleForm.applyDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="核定担保金额：">
                    <el-input :disabled="true" v-model="ruleForm.applyGuaranteeAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="核定生效日期：">
                    <el-input :disabled="true" v-model="ruleForm.applyEffectiveDate" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="起保时间：">
                    <el-input :disabled="true" v-model="ruleForm.applyStartTime" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保障天数(日历天)：">
                    <el-input :disabled="true" v-model="ruleForm.applyDepositDays" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="终保时间：">
                    <el-input :disabled="true" v-model="ruleForm.applyEndTime" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保函费率：">
                    <el-input :disabled="true" v-model="ruleForm.applyPremium" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保费金额：">
                    <el-input :disabled="true" v-model="ruleForm.applyPremiumAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div style="margin-top:50px;">
              <div class="img-tip">
                <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                <div class="number-tip">附件</div>
              </div>
              <div style="margin-top:35px;display: flex;justify-content: space-between;flex-wrap: wrap;">
                <div class="annex">
                  <el-form-item label="审计报告：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in commitment_letter" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="财务报表：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in financial_statements" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
                <div class="annex">
                  <el-form-item label="其他附件：">
                    <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in apply_info_other" :key="item.id" @click="downLoadeFile(item.name)">
                      {{item.originalName}}
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w1300 SignUpForms-box" v-if="ruleForm.applyWay=='readyMoney'&&ruleForm.applyId!=-1&&ruleForm.applyId!=''">
          <div style="display: flex;justify-content:center;align-items: center;margin:50px 0 30px 0;">
            <div style="font-size: 24px;font-weight: 500;color: #000;">现金完善详情</div>
          </div>
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">现金完善详情</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="ruleForm" label-width="120" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="承保机构:" prop="institutionName">
                    <el-input disabled placeholder="请输入承保机构" v-model="ruleForm.institutionName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="存储金额:" prop="premiumAmount">
                    <el-input disabled placeholder="请输入存储金额" v-model="ruleForm.applyPremiumAmount " style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="承保机构联系方式:" prop="institutionContact">
                    <el-input disabled placeholder="请输入承保机构联系方式" v-model="ruleForm.institutionContact" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="投保人类型:" prop="bidType">
                    <el-input disabled placeholder="请输入投保人类型" v-model="ruleForm.bidType" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目全称:" prop="projectName">
                    <el-input :disabled='true' placeholder="请输入项目全称" v-model="ruleForm.projectName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目编号:" prop="projectCode">
                    <el-input :disabled='true' placeholder="请输入项目编号" v-model="ruleForm.projectCode" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元):" prop="contractAmount">
                    <el-input :disabled='true' placeholder="请输入合同金额" v-model="ruleForm.contractAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(天):" prop="contractDuration">
                    <el-input :disabled='true' placeholder="请输入合同工期" v-model="ruleForm.contractDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="企业资质:" prop="applyQualifications">
                    <el-input disabled placeholder="请输入企业资质" v-model="ruleForm.applyQualifications" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间:" prop="projectStartTime">
                    <el-date-picker disabled v-model="ruleForm.projectStartTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择开工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="完工时间:" prop="projectEndTime">
                    <el-date-picker disabled v-model="ruleForm.projectEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择完工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="工期(天):" prop="duration">
                    <el-input disabled placeholder='请输入工期' v-model="ruleForm.applyDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="现金存储金额:" prop="guaranteeAmount">
                    <el-input :disabled='true' placeholder='请输入现金存储金额' v-model="ruleForm.applyGuaranteeAmount" style="width: 260px;height:40px;">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="核定生效日期:" prop="verifyTime">
                    <el-date-picker :disabled='true' v-model="ruleForm.applyEffectiveDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择核定生效日期" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="缴存时间:" prop="startTime">
                    <el-date-picker disabled v-model="ruleForm.applyStartTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择缴存时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="结束时间:" prop="endTime">
                    <el-date-picker disabled v-model="ruleForm.applyEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择结束时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <div style="margin-top:10px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                      <div class="number-tip">附件</div>
                    </div>
                    <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap: wrap;">
                      <div class="annex">
                        <el-form-item label="缴费凭证：">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in pay_certificate" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div class="w1300 SignUpForms-box" v-if="ruleForm.applyWay=='bank'&&ruleForm.applyId!=-1&&ruleForm.applyId!=''">
          <div style="display: flex;justify-content:center;align-items: center;margin:50px 0 30px 0;">
            <div style="font-size: 24px;font-weight: 500;color: #000;">银行完善详情</div>
          </div>
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">银行完善详情</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="ruleForm" label-width="120" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="承保机构:" prop="institutionName">
                    <el-input disabled placeholder="请输入承保机构" v-model="ruleForm.institutionName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保费金额:" prop="premiumAmount">
                    <el-input disabled placeholder="请输入保费金额" v-model="ruleForm.applyPremiumAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保函编号:" prop="guaranteeSn">
                    <el-input disabled placeholder="请输入保函编号" v-model="ruleForm.guaranteeSn" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="承保机构联系方式:" prop="institutionContact">
                    <el-input disabled placeholder="请输入承保机构联系方式" v-model="ruleForm.institutionContact" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="投保人类型:" prop="bidType">
                    <el-input disabled placeholder="请输入投保人类型" v-model="ruleForm.bidType" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目全称:" prop="projectName">
                    <el-input :disabled='true' placeholder="请输入项目全称" v-model="ruleForm.projectName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目编号:" prop="projectCode">
                    <el-input :disabled='true' placeholder="请输入项目编号" v-model="ruleForm.projectCode" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元):" prop="contractAmount">
                    <el-input :disabled='true' placeholder="请输入合同金额" v-model="ruleForm.contractAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(天):" prop="contractDuration">
                    <el-input :disabled='true' placeholder="请输入合同工期" v-model="ruleForm.contractDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="企业资质:" prop="qualifications">
                    <el-input disabled placeholder="请输入企业资质" v-model="ruleForm.applyQualifications" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间:" prop="projectStartTime">
                    <el-date-picker disabled v-model="ruleForm.projectStartTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择开工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="完工时间:" prop="projectEndTime">
                    <el-date-picker disabled v-model="ruleForm.projectEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择完工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="工期(天):" prop="duration">
                    <el-input disabled placeholder='请输入工期' v-model="ruleForm.applyDuration" style="width: 260px;height:40px;">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="核定担保金额:" prop="guaranteeAmount">
                    <el-input :disabled='true' placeholder='请输入核定担保金额' v-model="ruleForm.guaranteeAmount" style="width: 260px;height:40px;">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="核定生效日期:" prop="verifyTime">
                    <el-date-picker :disabled='true' v-model="ruleForm.applyEffectiveDate" type="date" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择核定生效日期" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="起保时间:" prop="startTime">
                    <el-date-picker disabled v-model="ruleForm.applyStartTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择起保时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="结束时间:" prop="endTime">
                    <el-date-picker disabled v-model="ruleForm.applyEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择结束时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <div style="margin-top:10px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                      <div class="number-tip">附件</div>
                    </div>
                    <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap: wrap;">
                      <div class="annex">
                        <el-form-item label="保函附件：">
                          <div class="el-upload__tip" style="margin-right:10px;cursor: pointer;" v-for="item in guarantee_letter" :key="item.id" @click="downLoadeFile(item.name)">
                            {{item.originalName}}
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
    <el-dialog v-model="dialogVisible1" title="准入准则" width="50%">
      <div v-html="guidelineList"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible1 = false" size="large">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible2" title="投保须知" width="50%">
      <div v-html="noticeList"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false" size="large">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible3" title="保函条款" width="50%">
      <div v-html="clauseList"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible3 = false" size="large">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { searchSlefOrder, searchUnderwriting,reqIndustryCategory } from "@/api/index.js"
let formSize = ref('default')
let dialogVisible1 = ref(false)
let dialogVisible2 = ref(false)
let dialogVisible3 = ref(false)
let router = useRouter();
let guidelineList = ref()
let noticeList = ref()
let clauseList = ref()
let institutionName = ref()
let UnderwritingList = ref({})
let ratifying = ref({})
let ruleForm = ref({})
let apply_business_license = ref([])
let general_onstruction_contract = ref([])
let bid_win_notice = ref([])
let licence = ref([])
let pay_certificate = ref([])
let qualifications = ref([])
let license_certificate = ref([])
let other = ref([])
let guarantee_letter = ref([])
let commitment_letter = ref([])
let financial_statements = ref([])
let apply_info_other = ref([])
let industryCategoryArr = ref([]) // 行业类别
let goBack = () => {
  router.push({ path: '/personalCenter' })
  localStorage.setItem('active', '2');
}
let detailList = async () => {
  await searchSlefOrder({ projectId: router.currentRoute.value.query.ids }).then(res => {
    ratifying.value = res.data.ratifying
    ruleForm.value = res.data.orderDetail
    institutionName.value = res.data.orderDetail.institutionName
    res.data.projectList.forEach(item => {
      if (item.attType == "apply_business_license") {
        apply_business_license.value.push(item)
      } else if (item.attType == "general_onstruction_contract") {
        general_onstruction_contract.value.push(item)
      } else if (item.attType == "bid_win_notice") {
        bid_win_notice.value.push(item)
      } else if (item.attType == "licence") {
        licence.value.push(item)
      } else if (item.attType == "qualifications") {
        qualifications.value.push(item)
      } else if (item.attType == "license_certificate") {
        license_certificate.value.push(item)
      } else if (item.attType == "project_other") {
        other.value.push(item)
      }
    })
    res.data && res.data.applyList &&res.data.applyList.forEach(item => {
      if (item.attType == "commitment_letter") {
        commitment_letter.value.push(item)
      } else if (item.attType == "financial_statements") {
        financial_statements.value.push(item)
      } else if (item.attType == "apply_info_other") {
        apply_info_other.value.push(item)
      } else if (item.attType == "pay_certificate") {
        pay_certificate.value.push(item)
      } else if (item.attType == "guarantee_letter") {
        guarantee_letter.value.push(item)
      }
    })
  })
}
let downLoadeFile = (url) => {
  window.open(getDynamicImageUrl(url))
}
let getDynamicImageUrl = (url) => {
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const baseUrl = `${parts[0]}//${parts[2]}`;
  return `${baseUrl}` + '/' + `${url}`;
}
let guideline = (value) => {
  guidelineList.value = value
  dialogVisible1.value = true
}
let notice = (value) => {
  noticeList.value = value
  dialogVisible2.value = true
}
let clause = (value) => {
  clauseList.value = value
  dialogVisible3.value = true
}
let Underwriting = async () => {
  await searchUnderwriting({ id: router.currentRoute.value.query.applyInstitutionId }).then(res => {
    UnderwritingList.value = res.data
  })
}
// 获取行业内类别
let getIndustryCategory = async () => {
  await reqIndustryCategory('industry_category').then((res) => {
    if(res.code == 200 ) {
       industryCategoryArr.value= res.data || []
    }
  })
}
onMounted(async () => {
  await detailList()
  await Underwriting()
  await getIndustryCategory()
})
</script>
<style scoped>
.annex ::v-deep .el-form-item {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.annex ::v-deep .el-form-item__label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 22px;
}
::v-deep .el-form-item__label {
  line-height: inherit !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
.el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(182, 186, 210, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
  margin: 20px 0;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.SignUpForms-box {
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.title-img {
  width: 360px;
  height: 119px;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -105px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 18px 34px 15px 34px;
  box-sizing: border-box;
}
.title-tip-box {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 5px;
}
.questionMark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgb(255, 141, 26);
  color: #fff;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
}
.questionMark-title {
  font-size: 20px;
  font-weight: 700;
  color: rgba(31, 112, 247, 1);
}
.ExclamationMark-box {
  width: 504px;
  display: flex;
  align-items: baseline;
  padding: 10px 0;
  box-sizing: border-box;
}
.ExclamationMark-box:nth-child(2n) {
  margin-left: 65px;
}
.ExclamationMark-box:nth-child(4) {
  width: auto;
}
.ExclamationMark {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ff8d1a;
  color: #ff8d1a;
  text-align: center;
  line-height: 16px;
  margin-right: 10px;
}
.ExclamationMark-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
.btn-false {
  border-radius: 6px;
  background: rgb(236, 245, 255);
  border: 1px solid rgb(59, 133, 244);
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  font-size: 12px;
  color: rgba(59, 133, 244, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 3px 8px;
  margin-left: 10px;
}
.btn-false:nth-child(1) {
  margin-left: 0;
}
</style>

