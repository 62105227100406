<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="w1300 SignUpForms-box">
          <div style="display: flex;align-items: center;margin:50px 0 30px 0;">
            <div style="display: flex;align-items: center;cursor: pointer;" @click="goBack" v-if="showback1">
              <div><img src="./../../static/back.png" alt="" style="width: 13px;height: 14px;"></div>
              <div style="font-size: 16px;color: rgb(0, 82, 217);margin-left:5px;">返回订单列表
              </div>
            </div>
            <div style="font-size: 24px;font-weight: 500;color: #000;margin:auto;" v-if="showback">企业信息管理</div>
            <div style="font-size: 24px;font-weight: 500;color: #000;margin:auto;" v-if="!showback">企业信息修改审核中</div>
          </div>
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../../static/01-img.png" alt=""></div>
                <div class="number-tip">账号信息</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="登录账号：" prop="account">
                    <el-input :disabled='true' v-model="ruleForm.account" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="手机号：" prop="phone">
                    <el-input :disabled='true' v-model="ruleForm.phone" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <div style="margin-top:50px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../../static/02-img.png" alt=""></div>
                      <div class="number-tip">企业基本信息</div>
                    </div>
                    <div style="margin-top:35px;">
                      <el-form-item label="企业名称：" prop="companyName">
                        <el-input :disabled='!showback' v-model="ruleForm.companyName" placeholder="请输入企业名称" style="width: 230px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="联系方式：" prop="contractInformation">
                        <el-input :disabled='!showback' v-model="ruleForm.contractInformation" placeholder="请输入联系方式" style="width: 230px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="法定代表：" prop="legalRepresentative">
                        <el-input :disabled='!showback' v-model="ruleForm.legalRepresentative" placeholder="请输入法人代表" style="width: 230px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="统一社会信用代码：" prop="creditCode">
                        <el-input :disabled='!showback' v-model="ruleForm.creditCode" placeholder="请输入统一社会信用代码" style="width: 230px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="基本开户行：" prop="openingBank">
                        <el-input :disabled='!showback' v-model="ruleForm.openingBank" placeholder="请输入基本开户行" style="width: 230px;height:40px;" />
                      </el-form-item>
                      <el-form-item label="基本户账号：" prop="bank">
                        <el-input :disabled='!showback' v-model="ruleForm.bank" style="width: 230px;height:40px;" placeholder="请输入基本户账号" />
                      </el-form-item>
                      <el-form-item label="公司地址：" prop="address">
                        <el-input :disabled='!showback' v-model="ruleForm.address" style="width: 230px;height:40px;" placeholder="请输入公司地址" />
                      </el-form-item>
                      <el-form-item label="企业邮箱：" prop="email">
                        <el-input :disabled='!showback' v-model="ruleForm.email" style="width: 230px;height:40px;" placeholder="请输入企业邮箱" />
                      </el-form-item>
                      <el-form-item label="身份证件号：" prop="idCard">
                        <el-input :disabled='!showback' v-model="ruleForm.idCard" style="width: 230px;height:40px;" placeholder="请输入身份证件号" />
                      </el-form-item>
                      <el-form-item label="营业期限结束日期：" prop="expirationTime">
                        <el-date-picker :disabled='!showback' v-model="ruleForm.expirationTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请输入营业期限结束日期" style="width: 230px;height:40px;" />
                        <!-- <el-input :disabled='!showback' v-model="ruleForm.expirationTime" placeholder="请输入营业期限结束日期" -->
                        <!-- style="width: 230px;height:40px;" /> -->
                      </el-form-item>
                      <el-form-item label="所属区域:">
                        <el-input disabled v-model="ruleForm.districtName" style="width: 230px;height:40px;" placeholder="请输入所属区域" />
                      </el-form-item>
                      <el-form-item label="邮编:" prop="postcode">
                        <el-input :disabled='!showback' v-model="ruleForm.postcode" style="width: 230px;height:40px;" placeholder="请输入邮编" />
                      </el-form-item>
                    </div>
                  </div>
                  <div style="margin-top:50px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../../static/03-img.png" alt=""></div>
                      <div class="number-tip">附件</div>
                    </div>
                    <div style="margin-top:35px;display:flex;justify-content: space-between;">
                      <div class="annex">
                        <el-form-item label="身份证正面照" prop="idCardPositiveLinks">
                          <el-upload :disabled='!showback' v-model:file-list="id_card_positive" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove1" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile1">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="身份证反面照" prop="idCardBackLinks">
                          <el-upload :disabled='!showback' v-model:file-list="id_card_back" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove2" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile2">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div>
                      <div class="annex">
                        <el-form-item label="营业执照" prop="businessLicenseLinks">
                          <el-upload :disabled='!showback' v-model:file-list="business_license" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove3" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile3">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;padding-top: 50px;" v-if="showback">
              <el-button type="primary" @click="submitForm(ruleFormRef)" :disabled='disabledSubmit' style="width: 150px;height: 40px;font-size: 14px;">提交修改申请</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import { Upload } from '@element-plus/icons-vue'
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { searchCompany, companyInfo, underReview, companyInfoUpdate, getLastRecord } from "@/api/index.js"
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let router = useRouter()
let formSize = ref('default')
let showback = ref(true)
let disabledSubmit = ref(false)
let showback1 = ref(true)
let ruleFormRef = ref()
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let ruleForm = ref({
  // account: '',
  // companyName: '',
  // contractInformation: '',
  // legalRepresentative: '',
  // creditCode: '',
  // openingBank: '',
  // bank: '',
  // address: '',
  // email: '',
  // idCard: '',
  // expirationTime: '',
  // phone: '',
  idCardPositiveLinks: [],
  idCardBackLinks: [],
  businessLicenseLinks: [],
  attachList: []
})

let rules = ref({
  postcode: [
    { required: true, message: '请输入邮编', trigger: 'blur' }
  ],
  account: [
    { required: true, message: '请输入登录账号', trigger: 'blur' }
  ],
  companyName: [
    { required: true, message: '请输入企业名称', trigger: 'blur' },
  ],
  contractInformation: [
    { required: true, message: '请输入联系方式', trigger: 'blur' },
  ],
  legalRepresentative: [
    { required: true, message: '请输入法定代表', trigger: 'blur' },
  ],
  creditCode: [
    { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
  ],
  openingBank: [
    { required: true, message: '请输入基本开户行', trigger: 'blur' },
  ],
  bank: [
    { required: true, message: '请输入基本户账号', trigger: 'blur' },
  ],
  address: [
    { required: true, message: '请输入公司地址', trigger: 'blur' },
  ],
  email: [
    { required: true, message: '请输入企业邮箱', trigger: 'blur' },
  ],
  idCard: [
    { required: true, message: '请输入身份证件号', trigger: 'blur' },
  ],
  // expirationTime: [
  //   { required: true, message: '请输入营业期限结束日期', trigger: 'blur' },
  // ], 
  idCardPositiveLinks: [
    { required: true, message: '请上传身份证正面照', trigger: 'blur' },
  ], idCardBackLinks: [
    { required: true, message: '请上传身份证反面照', trigger: 'blur' },
  ], businessLicenseLinks: [
    { required: true, message: '请上传营业执照', trigger: 'blur' },
  ],
  phone: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入手机号长度为11位', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
  ]
})
let id_card_positive = ref([])
let id_card_back = ref([])
let business_license = ref([])
let submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (ruleForm.value.idCardPositiveLinks && ruleForm.value.idCardPositiveLinks != '') {
      ruleFormRef.value.clearValidate('idCardPositiveLinks');
    }
    if (ruleForm.value.idCardBackLinks && ruleForm.value.idCardBackLinks != '') {
      ruleFormRef.value.clearValidate('idCardBackLinks');
    }
    if (ruleForm.value.businessLicenseLinks && ruleForm.value.businessLicenseLinks != '') {
      ruleFormRef.value.clearValidate('businessLicenseLinks');
    }

    if (valid) {
      ruleForm.value.idCardPositiveLinks.forEach(item => {
        delete item['id']
      })
      ruleForm.value.idCardBackLinks.forEach(item => {
        delete item['id']
      })
      ruleForm.value.businessLicenseLinks.forEach(item => {
        delete item['id']
      })
      ruleForm.value.attachList = ruleForm.value.idCardPositiveLinks.concat(ruleForm.value.idCardBackLinks).concat(ruleForm.value.businessLicenseLinks)
      delete ruleForm.value['id']
      console.log('submit!', ruleForm.value);
      companyInfoUpdate(ruleForm.value).then(res => {
        if (res.code == 200 && res.success) {
          ElMessage.success('修改成功');
          router.push('/index')
          disabledSubmit.value = true;
        }
      })
      // router.push('/businessRegistrationSuccess')
    } else {
      console.log('error submit!', fields);
    }
  });
};
let successFile1 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.idCardPositiveLinks) {
    ruleForm.value.idCardPositiveLinks = [];
  }
  if (response.code == 200) {
    ruleForm.value.idCardPositiveLinks.push({ attId: response.data.attachId, attType: 'id_card_positive' })
    ruleFormRef.value.clearValidate('idCardPositiveLinks');
  }
}
let successFile2 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.idCardBackLinks) {
    ruleForm.value.idCardBackLinks = [];
  }
  if (response.code == 200) {
    ruleForm.value.idCardBackLinks.push({ attId: response.data.attachId, attType: 'id_card_back' })
    ruleFormRef.value.clearValidate('idCardBackLinks');
  }
}
let successFile3 = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.businessLicenseLinks) {
    ruleForm.value.businessLicenseLinks = [];
  }
  if (response.code == 200) {
    ruleForm.value.businessLicenseLinks.push({ attId: response.data.attachId, attType: 'business_license' })
  }
}
let resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}
let beforeUpload = (file) => {
  console.log(file, 'file');
  let isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!isJPG) {
    ElMessage.warning('上传文件只能是 JPG/PNG/JPEG 格式!');
    return false;
  }
  let maxSize = 10 * 1024 * 1024;
  if (file.size > maxSize) {
    ElMessage.warning('文件大小超过限制，请选择小于10MB的文件。');
  } else {
    console.log('文件大小符合要求，可以上传。');
  }
  return true;
}
let handleRemove1 = (file, uploadFiles) => {
  ruleForm.value.idCardPositiveLinks = []
}
let handleRemove2 = (file, uploadFiles) => {
  ruleForm.value.idCardBackLinks = []
}
let handleRemove3 = (file, uploadFiles) => {
  ruleForm.value.businessLicenseLinks = []
}
let getDynamicImageUrl = (url) => {
  let currentUrl = window.location.href;
  let parts = currentUrl.split('/');
  let baseUrl = `${parts[0]}//${parts[2]}`;
  console.log(`${baseUrl}` + '/' + `${url}`);
  return `${baseUrl}` + '/' + `${url}`;
}
let handlePreview = (uploadFile) => {
  ElMessageBox.confirm('确认下载此附件吗？', '附件下载',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      window.open(getDynamicImageUrl(uploadFile.url))
    }).catch(() => {
      return
    })
}

let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `仅支持上传一个文件！`
  )
}

let beforeRemove = (uploadFile, uploadFiles) => {
  return true
}
let goBack = () => {
  router.push({ path: '/personalCenter' })
  localStorage.setItem('active', '2');
}
onMounted(async () => {
  await searchCompany().then(res => {
    console.log(res.data.state);
    // 审核中 状态为1或99  不展示返回按钮
    if (res.data.state == 1 || res.data.state == 99) {
      showback1.value = false
    }
    // // 审核中 状态为99
    // if (res.data.state == 99) {
    //   showback1.value = false
    // }
  })
  let deptId = JSON.parse(localStorage.getItem('token')).dept_id;
  await getLastRecord(deptId).then(res => {
    if (res.data && res.data.verifyState == 1) {
      showback.value = false
    }
  })
  await underReview().then(res => {
    // true 为 存在审核中的数据
    if (res.data) {
      disabledSubmit.value = true;
    }
  })

  await companyInfo().then(res => {
    ruleForm.value.districtName = res.data.districtName
    ruleForm.value.district = res.data.district
    ruleForm.value.account = res.data.account
    ruleForm.value.companyName = res.data.companyName
    ruleForm.value.contractInformation = res.data.contractInformation
    ruleForm.value.legalRepresentative = res.data.legalRepresentative
    ruleForm.value.creditCode = res.data.creditCode
    ruleForm.value.openingBank = res.data.openingBank
    ruleForm.value.bank = res.data.bank
    ruleForm.value.address = res.data.address
    ruleForm.value.email = res.data.email
    ruleForm.value.expirationTime = res.data.expirationTime
    ruleForm.value.idCard = res.data.idCard
    ruleForm.value.phone = res.data.phone
    ruleForm.value.infoType = 'EDIT'
    ruleForm.value.deptId = res.data.deptId
    ruleForm.value.password = res.data.password
    ruleForm.value.postcode = res.data.postcode
    ruleForm.value.registerType = 1
    res.data.attachList.forEach(item => {
      if (item.attType == "id_card_positive") {
        id_card_positive.value.push({ name: item.originalName, url: item.name, attId: item.attId, attType: item.attType })
        ruleForm.value.idCardPositiveLinks = [];
        ruleForm.value.idCardPositiveLinks.push(item)
        console.log(ruleForm.value.idCardPositiveLinks, 'id_card_positive');
      } else if (item.attType == "id_card_back") {
        id_card_back.value.push({ name: item.originalName, url: item.name, attId: item.attId, attType: item.attType })
        ruleForm.value.idCardBackLinks = [];
        ruleForm.value.idCardBackLinks.push(item)

      } else if (item.attType == "business_license") {
        business_license.value.push({ name: item.originalName, url: item.name, attId: item.attId, attType: item.attType })
        ruleForm.value.businessLicenseLinks = [];
        ruleForm.value.businessLicenseLinks.push(item)

      }
    })
  })

})
</script>
<style scoped>
.annex ::v-deep .el-form-item {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.annex ::v-deep .el-form-item--default .el-form-item__label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
::v-deep .upload-demo {
  width: 100%;
}
::v-deep .el-cascader .el-input {
  height: 100%;
}
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
.annex ::v-deep .el-form-item__label-wrap {
  display: flex;
  margin-left: 0 !important;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
.el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(182, 186, 210, 1);
  white-space: nowrap;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.SignUpForms-box {
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  background: url("./../../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.title-img {
  width: 360px;
  height: 119px;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -105px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 18px 34px 15px 34px;
  box-sizing: border-box;
}
.title-tip-box {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 5px;
}
.questionMark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgb(255, 141, 26);
  color: #fff;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
}
.questionMark-title {
  font-size: 20px;
  font-weight: 700;
  color: rgba(31, 112, 247, 1);
}
.ExclamationMark-box {
  width: 504px;
  display: flex;
  align-items: baseline;
  padding: 10px 0;
  box-sizing: border-box;
}
.ExclamationMark-box:nth-child(2n) {
  margin-left: 65px;
}
.ExclamationMark-box:nth-child(4) {
  width: auto;
}
.ExclamationMark {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ff8d1a;
  color: #ff8d1a;
  text-align: center;
  line-height: 16px;
  margin-right: 10px;
}
.ExclamationMark-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
</style>
