<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div>
        <div class="header-img">
          <div class="title-img"><img src="./../static/businessRegistration.png" alt=""></div>
          <div class="title-tip w1300">
            <div class="title-tip-box">
              <div class="questionMark">?</div>
              <div class="questionMark-title">帮助说明</div>
            </div>
            <div style="display: flex;flex-wrap: wrap;">
              <div v-for="item in titleArr" :key="item.id" class="ExclamationMark-box">
                <div class="ExclamationMark">!</div>
                <div class="ExclamationMark-title">{{item.title}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="SignUpForms">
          <div class="w1300 SignUpForms-box">
            <div class="shadow">
              <el-form label-position="right" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                <div>
                  <div class="img-tip">
                    <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                    <div class="number-tip">账号信息</div>
                  </div>
                  <div style="margin-top:35px;">
                    <el-form-item label="登录账号：" prop="account">
                      <el-input v-model="ruleForm.account" placeholder="请输入登录账号" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="密码：" prop="password">
                      <el-input v-model="ruleForm.password" placeholder="请输入密码" type='password' show-password style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="确认密码：" prop="surePassword">
                      <el-input v-model="ruleForm.surePassword" placeholder="请二次确认密码" type='password' show-password style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="手机号：" prop="phone">
                      <el-input v-model="ruleForm.phone" placeholder="请输入手机号" style="width: 230px;height:40px;" @input="activeyzm(ruleFormRef)" />
                    </el-form-item>
                    <el-form-item label="验证码：" prop="yzm">
                      <el-input v-model="ruleForm.yzm" placeholder="请输入验证码" style="width: 120px;height:40px;" />
                      <el-button :disabled='IsDisabled' type="primary" style="width: 100px;height:40px;margin-left: 10px;" @click="madeyzm">{{title}}</el-button>
                    </el-form-item>
                  </div>
                </div>
                <div style="margin-top:50px;">
                  <div class="img-tip">
                    <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                    <div class="number-tip">企业基本信息</div>
                  </div>
                  <div style="margin-top:35px;">
                    <el-form-item label="企业名称：" prop="companyName">
                      <el-input v-model="ruleForm.companyName" placeholder="请输入企业名称" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="联系方式：" prop="contractInformation">
                      <el-input v-model="ruleForm.contractInformation" placeholder="请输入联系方式" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="法定代表：" prop="legalRepresentative">
                      <el-input v-model="ruleForm.legalRepresentative" placeholder="请输入法定代表" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="统一社会信用代码：" prop="creditCode">
                      <el-input v-model="ruleForm.creditCode" placeholder="请输入统一社会信用代码" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="基本开户行：" prop="openingBank">
                      <el-input v-model="ruleForm.openingBank" placeholder="请输入基本开户行" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="基本户账号：" prop="bank">
                      <el-input v-model="ruleForm.bank" placeholder="请输入基本户账号" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="公司地址：" prop="address">
                      <el-input v-model="ruleForm.address" placeholder="请输入公司地址" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="企业邮箱：" prop="email">
                      <el-input v-model="ruleForm.email" placeholder="请输入企业邮箱" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="身份证件号：" prop="idCard">
                      <el-input v-model="ruleForm.idCard" placeholder="请输入身份证件号" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="营业期限结束日期：" prop="expirationTime">
                      <el-date-picker v-model="ruleForm.expirationTime" placeholder="请选择营业期限结束日期" value-format="YYYY-MM-DD HH:mm:ss" type="datetime" style="width: 230px;height:40px;" />
                    </el-form-item>
                    <el-form-item label="区域：" prop="district">
                      <!-- <el-input v-model="ruleForm.district" placeholder="请输入区域" style="width: 230px;height:40px;" /> -->
                      <el-cascader v-model="districtClone" placeholder='请选择区域' :options="treeArr" :props="optionProps" size='default' style="width: 230px;height: 40px;" @change="handleChange" />
                    </el-form-item>
                    <el-form-item label="邮编：" prop="postcode">
                      <el-input v-model="ruleForm.postcode" placeholder="请输入邮编" style="width: 230px;height:40px;" />
                    </el-form-item>
                  </div>
                </div>
                <div style="margin-top:50px;">
                  <div class="img-tip">
                    <div class="number-img"><img src="./../static/03-img.png" alt=""></div>
                    <div class="number-tip">附件</div>
                  </div>
                  <div style="width: 100%;margin-top:35px;display:flex;justify-content: space-between;">
                    <div class="annex">
                      <el-form-item label="身份证正面照" prop="idCardPositiveFileList">
                        <el-upload v-model:file-list="ruleForm.idCardPositiveFileList" class="upload-demo" action="" :auto-upload="false" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile1" ref="idCardPositiveRef1">
                          <el-button type="primary" :icon="Upload">上传附件</el-button>
                          <template #tip>
                            <div class="el-upload__tip">
                              请上传jpg/png/jpeg文件
                            </div>
                          </template>
                        </el-upload>
                        <div>
                          <div class="tit">详细要求：</div>
                          <div class="jt">1.上传法人注册登记证照原件扫描件或照片</div>
                          <div class="jt">2.照片所有信息需清晰可见</div>
                          <div class="jt">3.内容真实有效，不得做任何修改</div>
                          <div class="jt">4.照片支持<span class="tit">jpeg、jpg、png</span>格式</div>
                          <div class="jt">5.上传文件不能超过<span class="tit">10M</span></div>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="annex">
                      <el-form-item label="身份证反面照" prop="idCardBackFileList">
                        <el-upload v-model:file-list="ruleForm.idCardBackFileList" class="upload-demo" action="" :auto-upload="false" multiple :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile2" ref="idCardPositiveRef2">
                          <el-button type="primary" :icon="Upload">上传附件</el-button>
                          <template #tip>
                            <div class="el-upload__tip">
                              请上传jpg/png/jpeg文件
                            </div>
                          </template>
                        </el-upload>
                        <div>
                          <div class="tit">详细要求：</div>
                          <div class="jt">1.上传法人注册登记证照原件扫描件或照片</div>
                          <div class="jt">2.照片所有信息需清晰可见</div>
                          <div class="jt">3.内容真实有效，不得做任何修改</div>
                          <div class="jt">4.照片支持<span class="tit">jpeg、jpg、png</span>格式</div>
                          <div class="jt">5.上传文件不能超过<span class="tit">10M</span></div>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="annex">
                      <el-form-item label="营业执照" prop="businessLicenseFileList">
                        <el-upload v-model:file-list="ruleForm.businessLicenseFileList" class="upload-demo" action="" :auto-upload="false" multiple :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile3" ref="idCardPositiveRef3">
                          <el-button type="primary" :icon="Upload">上传附件</el-button>
                          <template #tip>
                            <div class="el-upload__tip">
                              请上传jpg/png/jpeg文件
                            </div>
                          </template>
                        </el-upload>
                        <div>
                          <div class="tit">详细要求：</div>
                          <div class="jt">1.上传法人注册登记证照原件扫描件或照片</div>
                          <div class="jt">2.照片所有信息需清晰可见</div>
                          <div class="jt">3.内容真实有效，不得做任何修改</div>
                          <div class="jt">4.照片支持<span class="tit">jpeg、jpg、png</span>格式</div>
                          <div class="jt">5.上传文件不能超过<span class="tit">10M</span></div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>
              <div style="display: flex;justify-content: center;align-items: center;padding-top: 50px;">
                <el-button type="primary" @click="submitForm(ruleFormRef)" style="width: 150px;height: 40px;font-size: 14px;">提交申请</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import { Upload } from '@element-plus/icons-vue'
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { ElMessage } from 'element-plus'
import { submitCompanyInfo, registerPhonevalidate, lazyTree, checkoutAccountApi, checkoutPhoneApi } from "@/api/index.js";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let title = ref('获取验证码')
let CaptchaKey = ref()
let treeArr = ref()
let optionProps = ref({
  value: "id",
  label: "title",
  children: "children"
})
let IsDisabled = ref(true)
let titleArr = ref([
  { id: 1, title: "注册账号时，需要通过手机号码接收短信验证码，请正确填写您的手机号码。" },
  { id: 2, title: "注册的账号，需要通过实名认证后才能正常使用。" },
  { id: 3, title: "注册账号后，请牢记注册的登录账号和密码，切勿向他人泄漏。" },
  // { id: 4, title: "由于怀集县农民工用工管理平台需要，请注册成功后及时办理支持四川互认的CA数字证书。" },
  { id: 4, title: "企业账户可在“个人中心”页面进行业务处理。" },
])
let router = useRouter()
let formSize = ref('default')
let ruleFormRef = ref()
let idCardPositiveRef1 = ref()
let idCardPositiveRef2 = ref()
let idCardPositiveRef3 = ref()
let districtClone = ref([])
let ruleForm = ref({
  account: '',
  password: '',
  surePassword: '',
  phone: '',
  yzm: '',
  postcode: '',
  district: '',
  idCardBackFileList: [],
  businessLicenseFileList: [],
  idCardPositiveFileList: [],
})
let validateTelTime = (rule, value, callback) => {
  if (value != ruleForm.value.password) {
    callback(new Error('请确认密码与原密码一致'));
  } else {
    callback();
  }
}
let checkoutAccount = (rule, value, callback) => {
  checkoutAccountApi(ruleForm.value.account).then((res) => {
    if (res.code !== 200) {
      callback(new Error('该账号已存在'));
    } else {
      callback();
    }
  })
}
let checkoutPhone = (rule, value, callback) => {
  checkoutPhoneApi(ruleForm.value.phone).then((res) => {
    if (res.code !== 200) {
      callback(new Error('该手机号已存在'));
    } else {
      callback();
    }
  })
}

let validatorPassword = (rule, value, callback) => {
  // 定义正则表达式来检查长度8-16并包含字母和数字 特殊字符
  const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/
  if (pattern.test(value)) {
    callback()
  } else {
    callback(new Error('密码必须包含字母、数字和特殊字符'))
  }
}

let rules = ref({
  account: [
    { required: true, message: '请输入登录账号', trigger: 'blur' },
    { min: 3, max: 16, message: '请输入登录账号长度在3-16', trigger: 'blur' },
    { required: true, validator: checkoutAccount, trigger: 'blur' },
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 8, max: 16, message: '密码长度至少8位', trigger: 'blur' },
    { required: true, trigger: 'blur',validator: validatorPassword },
  ],
  surePassword: [
    { required: true, message: '请输入确认密码', trigger: 'blur' },
    { min: 8, max: 16, message: '密码长度至少8位', trigger: 'blur' },
    { required: true, validator: validateTelTime, trigger: 'blur' }
  ],
  phone: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入手机号长度为11位', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
    { required: true, validator: checkoutPhone, trigger: 'blur' },
  ],
  yzm: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
  companyName: [
    { required: true, message: '请输入企业名称', trigger: 'blur' },
  ],
  contractInformation: [
    { required: true, message: '请输入联系方式', trigger: 'blur' },
    { min: 11, max: 11, message: '联系方式长度为11位', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的联系方式', trigger: 'blur' }
  ],
  legalRepresentative: [
    { required: true, message: '请输入法定代表', trigger: 'blur' },
  ],
  creditCode: [
    { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
    { min: 18, max: 18, message: '统一社会信用代码长度为18位', trigger: 'blur' },
    { pattern: /^[A-Za-z0-9]{18}$/, message: '请输入正确的统一社会信用代码', trigger: 'blur' }
  ],
  openingBank: [
    { required: true, message: '请输入基本开户行', trigger: 'blur' },
  ],
  bank: [
    { required: true, message: '请输入基本户账号', trigger: 'blur' },
  ],
  address: [
    { required: true, message: '请输入公司地址', trigger: 'blur' },
  ],
  postcode: [
    { required: true, message: '请输入邮编', trigger: 'blur' },
  ],
  email: [
    { required: true, message: '请输入企业邮箱', trigger: 'blur' },
    { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱', trigger: 'blur' }
  ],
  idCard: [
    { required: true, message: '请输入身份证件号', trigger: 'blur' },
    { min: 18, max: 18, message: '身份证件号长度为18位', trigger: 'blur' },
    {
      pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
      message: '证件号码格式有误！',
      trigger: 'blur'
    }
  ],
  expirationTime: [
    { required: true, message: '请选择营业期限结束日期', trigger: 'change' },
  ],
  grantingBank: [
    { required: true, message: '请输入授信银行', trigger: 'blur' },
  ],
  creditLimit: [
    { required: true, message: '请输入授信额度', trigger: 'blur' },
  ],
  underwritingLimit: [
    { required: true, message: '请输入已承保额度', trigger: 'blur' },
  ],
  district: [
    { required: true, message: '请输入区域', trigger: 'change' },
  ],
  idCardBackFileList: [{ required: true, message: '请上传身份证正面照', trigger: 'blur' },],
  businessLicenseFileList: [{ required: true, message: '请上传身份证反面照', trigger: 'blur' },],
  idCardPositiveFileList: [{ required: true, message: '请上传营业执照', trigger: 'blur' },],
})
let successFile1 = (response, uploadFile, uploadFiles) => {
  if (response.code == 200) {
    ruleFormRef.value.clearValidate('idCardBackFileList');
  }
}
let successFile2 = (response, uploadFile, uploadFiles) => {
  if (response.code == 200) {
    ruleFormRef.value.clearValidate('businessLicenseFileList');
  }
}
let successFile3 = (response, uploadFile, uploadFiles) => {
  if (response.code == 200) {
    ruleFormRef.value.clearValidate('idCardPositiveFileList');
  }
}
let submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (idCardPositiveRef1.value) {
        idCardPositiveRef1.value.submit();
      }
      if (idCardPositiveRef2.value) {
        idCardPositiveRef2.value.submit();
      }
      if (idCardPositiveRef3.value) {
        idCardPositiveRef3.value.submit();
      }
      let formData = new FormData();
      formData.append("idCardPositive", ruleForm.value.idCardPositiveFileList[0].raw);
      formData.append("idCardBack", ruleForm.value.idCardBackFileList[0].raw);
      formData.append("businessLicense", ruleForm.value.businessLicenseFileList[0].raw);
      ruleForm.value.infoType = 'REGISTER'
      ruleForm.value.registerType = 1
      let params = ruleForm.value;
      Object.keys(params).forEach(function (key) {
        formData.append(key, params[key]);
      })
      submitCompanyInfo(ruleForm.value.yzm, CaptchaKey.value, formData).then(() => {
        router.push('/businessRegistrationSuccess')
      }, error => {
        window.console.log(error);
      });

    } else {
      console.log('error submit!', fields);
    }
  });
};
let beforeUpload = (file) => {
  let isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!isJPG) {
    ElMessage.warning('上传文件只能是 JPG/PNG/JPEG 格式!');
    return false;
  }
  let maxSize = 10 * 1024 * 1024;
  if (file.size > maxSize) {
    ElMessage.warning('文件大小超过限制，请选择小于10MB的文件。');
  } else {
    console.log('文件大小符合要求，可以上传。');
  }
  return true;
}
let handleRemove = (file, uploadFiles) => {
  console.log(file, uploadFiles)
}
let handlePreview = (uploadFile) => {
  console.log(uploadFile)
}
let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 1, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length
    } totally`
  )
}
let beforeRemove = (uploadFile, uploadFiles) => {
  return true
}
let activeyzm = (formName) => {
  formName.validateField('phone', (errorMessage) => {
    if (errorMessage) {
      IsDisabled.value = false;
    } else {
      IsDisabled.value = true;
      console.log('Phone validation error:', errorMessage);
    }
  });
}
let madeyzm = async () => {
  await registerPhonevalidate({ phone: ruleForm.value.phone }).then((res) => {
    console.log(res, 'res');
    CaptchaKey.value = res.data.id
  })
  let count = 60;
  title.value = `${count}秒后重新获取`;
  IsDisabled.value = true;
  let timer = setInterval(() => {
    count--;
    if (count > 0) {
      title.value = `${count}秒后重新获取`;
    } else {
      clearInterval(timer);
      title.value = '获取验证码';
      IsDisabled.value = false;
    }
  }, 1000);
}
let handleChange = (value) => {
  ruleForm.value.district = Object.values(value).join(',');
}
let lazyTreeArr = async () => {
  await lazyTree().then((res) => {
    let slicedData = res.data.slice(1);
    treeArr.value = slicedData
    localStorage.setItem('Tree', JSON.stringify(treeArr.value))
  })
}
onMounted(async () => {
  if (localStorage.getItem('Tree')) {
    treeArr.value = JSON.parse(localStorage.getItem('Tree'))
  } else {
    await lazyTreeArr()
  }
})
</script>
<style scoped>
::v-deep .el-cascader .el-input {
  height: 100%;
}
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
.annex ::v-deep .el-form-item__label-wrap {
  margin-left: 0 !important;
}
.annex ::v-deep .el-form-item {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.annex ::v-deep .el-form-item--default .el-form-item__label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
.el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(182, 186, 210, 1);
  white-space: nowrap;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.tp150 {
  margin-top: 150px;
}
.SignUpForms-box {
  padding-top: 135px;
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -105px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 18px 34px 15px 34px;
  box-sizing: border-box;
}
.title-tip-box {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 5px;
}
.questionMark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgb(255, 141, 26);
  color: #fff;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
}
.questionMark-title {
  font-size: 20px;
  font-weight: 700;
  color: rgba(31, 112, 247, 1);
}
.ExclamationMark-box {
  width: 504px;
  display: flex;
  align-items: baseline;
  padding: 10px 0;
  box-sizing: border-box;
}
.ExclamationMark-box:nth-child(2n) {
  margin-left: 65px;
}
.ExclamationMark-box:nth-child(4) {
  width: auto;
}
.ExclamationMark {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ff8d1a;
  color: #ff8d1a;
  text-align: center;
  line-height: 16px;
  margin-right: 10px;
}
.ExclamationMark-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
</style>
